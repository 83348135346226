import { apiRequest } from './ApiService';

export const getAccListData = paramsData =>
  apiRequest().get('/account/list', { params: paramsData });

export const serachAccListData = (paramsData, pathVars) =>
  apiRequest().get('/account/search', {
    params: { ...paramsData, ...pathVars },
  });

export const postNewAcc = body => apiRequest().post(`/account`, { ...body });

export const putEditAcc = (body, id) =>
  apiRequest().put(`/account/${id}`, { ...body });

export const putEditPersonalAcc = body =>
  apiRequest().put(`/account/setting`, { ...body });

export const delAcc = path => apiRequest().delete(`/account/${path}`);

export const getAccData = () => apiRequest().get(`/account`);

export const sendEmail = body =>
  apiRequest().post(`/account/open-account/email`, { ...body });
