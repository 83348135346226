import { Input, Select, Space, message } from 'antd';
import { AccColums } from './AccColums';
import axios from 'axios';
import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { fetchWithPage } from '../services/ApiService';
import { serachAccListData } from '../services/AccApi';

const AccSearch = props => {
  const [searchValue] = useState({
    name: AccColums[0].dataIndex,
    value: false,
  });
  const [debouncedValue] = useDebounce(searchValue['value'], 500);

  const onSearch = async (params = {}) => {
    const res = await fetchWithPage(serachAccListData, params, {
      search_column_name: searchValue.name,
      search_value: debouncedValue,
    });
    props.setPagination({
      ...params,
      total: res.data.rowsCount,
    });
    props.setAccList(
      res.data.accountList.map((item, index) => ({
        key: index,
        ...item,
      }))
    );
  };

  useMemo(() => {
    //延遲讀取input
    debouncedValue !== false && onSearch(props.pagination);
  }, [debouncedValue]);

  const onChange = value => {
    searchValue['value'] = value;
    props.setPagination({ ...props.pagination, current: 1 });
  };
  return (
    <Space.Compact>
      <Select
        style={{ width: 180 }}
        defaultValue={AccColums[0].dataIndex}
        options={AccColums.filter(item => item.key !== 'permission').map(
          item => ({
            value: item.dataIndex,
            label: item.title,
          })
        )}
        onChange={v => {
          searchValue['name'] = v;
          searchValue['value'] = '';
          props.setPagination({ ...props.pagination, current: 1 });
        }}
        bordered={false}
      />
      <Input
        value={searchValue['value'] === false ? '' : searchValue['value']}
        onChange={v => onChange(v.target.value)}
      />
    </Space.Compact>
  );
};

export default AccSearch;
