import { message } from 'antd';
import { fetchApi } from '../../services/ApiService';
import {
  postNewAcc,
  putEditAcc,
  putEditPersonalAcc,
} from '../../services/AccApi';
export const AddNewAcc = async incomingData => {
  let key = 'NewAcc';
  let {
    value,
    editData,
    originEditData,
    from,
    form,
    Infro,
  } = incomingData.data;
  let { navigate, onCancel } = incomingData.setData;
  let account_info = value;
  editData &&
    Object.keys(account_info).forEach(data => {
      account_info[data] === editData[data] && delete account_info[data];
    });
  delete account_info['repw'];
  !account_info['password'] && delete account_info['password'];
  const res =
    from === 'personal'
      ? await fetchApi(putEditPersonalAcc, { account_info: account_info })
      : originEditData
      ? await fetchApi(
          putEditAcc,
          { account_info: account_info },
          editData.user_id
        )
      : await fetchApi(postNewAcc, { account_info: account_info });
  if (res.status === 200) {
    if (account_info['password'] && Infro?.user_name === editData?.user_name) {
      message.success({ content: '由於修改密碼，因此需要重新登入', key });
      navigate('/');
      localStorage.setItem('token', null);
    } else {
      message.success({ content: editData ? '已修改' : '已新增', key });
    }
    form.resetFields();
    onCancel();
  } else {
    message.error({ content: editData ? '修改失敗' : '新增失敗', key });
  }
};
