import React from 'react';
import { Layout } from 'antd';
import Main from './Main';
import SiderMenu from './siderMenu';
import Toolbar from './Toolbar';
import { Footer } from 'antd/es/layout/layout';

const PublicLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Toolbar />
      <Layout>
        <SiderMenu />
        <Main />
      </Layout>
      <Footer style={{ textAlign: 'center' }}>By Artifact</Footer>
    </Layout>
  );
};

export default PublicLayout;
