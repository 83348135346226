import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LockOutlined, UserOutlined, MailOutlined } from '@ant-design/icons';
import LoginBg from '../assets/LoginBg.png';
import style from './Login.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from 'react-simple-captcha';
import { fetchApi, loginApi } from '../services/ApiService';

const { Title } = Typography;

const LoginPage = () => {
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [loginError, setLoginError] = useState(false);
  const [forgetPwd, setForgetPwd] = useState(false);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const decodedMessage = decodeURIComponent(queryParams.get('message') || '');
  useEffect(() => {
    if (decodedMessage === '403') {
      message.error({ content: 'token已過期' });
    }
  }, [decodedMessage]);
  const Login = () => {
    let captchaValue = form.getFieldValue('captcha');
    setLoginError(false);
    if (validateCaptcha(captchaValue) === false) {
      message.error('驗證碼不相符');
      loadCaptchaEnginge(6);
    } else {
      LoginFetch();
    }
  };

  const LoginFetch = async () => {
    setloading(true);
    const obj = form.getFieldsValue();
    const res = await fetchApi(loginApi, {
      email: obj.email,
      password: obj.password,
    });
    if (res?.status === 200) {
      message.success('登錄成功!');
      localStorage.setItem('token', res.data.AT);
      navigate('/Article');
    } else {
      setloading(false);
      setLoginError(true);
      loadCaptchaEnginge(6);
    }
    setloading(false);
  };

  const [emailForm] = Form.useForm();

  const onEmailFormFinish = async value => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-api/account/forget-password/email`,
        { email: value.email }
      );

      if (res.status === 200) {
        message.success('已經送出，請檢查信箱');
        setForgetPwd(false);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error(
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const LoginForm = (
    <Form
      form={form}
      autoComplete='off'
      onFinish={() => {
        Login();
      }}
    >
      <div className={style.box}>
        <Form.Item className={style.header}>
          {/* <img
                  className={style.logo}
                  src={LoginLogo}
                  alt='LoginLogo'
                ></img> */}
          <Title level={2} className={style.companyName}>
            南迴人文與傳統生態知識管理後台
          </Title>
          <Title level={2} className={style.webName}>
            登入
          </Title>
        </Form.Item>
        <div className={style.input}>
          <Form.Item
            name='email'
            style={{ width: '50%' }}
            rules={[{ required: true, message: '請輸入Email' }]}
          >
            <Input
              size='large'
              placeholder='Email'
              prefix={<UserOutlined className={style.icon} />}
            />
          </Form.Item>
        </div>
        <div className={style.input}>
          <Form.Item
            name='password'
            style={{ width: '50%' }}
            rules={[{ required: true, message: '請輸入密碼' }]}
          >
            <Input.Password
              size='large'
              placeholder='密碼'
              prefix={<LockOutlined className={style.icon} />}
            />
          </Form.Item>
        </div>

        {loginError ? (
          <Form.Item className={style.loginError} wrapperCol={{ span: 13 }}>
            <Alert message='帳號或密碼輸入錯誤' type='error' showIcon />
          </Form.Item>
        ) : null}

        <Form.Item className={style.submit}>
          <Form.Item valuePropName='checked' noStyle>
            <Checkbox style={{ fontWeight: 500 }}>記得我</Checkbox>
          </Form.Item>
          <Button
            className={style.forgetPs}
            onClick={() => {
              setForgetPwd(true);
            }}
          >
            忘記密碼
          </Button>
        </Form.Item>

        <div className={style.input}>
          <LoadCanvasTemplate reloadColor='#009e82' />
          <Form.Item
            name='captcha'
            style={{ width: '25%' }}
            rules={[{ required: true, message: '請輸入驗證碼' }]}
          >
            <Input size='large' placeholder='驗證碼' />
          </Form.Item>
        </div>

        <br />
        <Form.Item className={style.submit}>
          <Button
            className={style.submitSize}
            size='large'
            type='primary'
            htmlType='submit'
            loading={loading}
          >
            登入
          </Button>
        </Form.Item>
      </div>
    </Form>
  );

  const EmailForm = (
    <Form form={emailForm} autoComplete='off' onFinish={onEmailFormFinish}>
      <div className={style.box}>
        <Form.Item className={style.header}>
          {/* <img
                  className={style.logo}
                  src={LoginLogo}
                  alt='LoginLogo'
                ></img> */}
          <MailOutlined className={style.title_icon} />
          <Title level={2} className={style.companyName}>
            修改新密碼的電郵
          </Title>
          <Title level={5} className={style.instruction}>
            系統會寄郵件，讓你修改密碼
          </Title>
        </Form.Item>
        <div className={style.input}>
          <Form.Item
            name='email'
            style={{ width: '50%' }}
            rules={[{ required: true, message: '請輸入Email' }]}
          >
            <Input
              size='large'
              placeholder='Email'
              prefix={<UserOutlined className={style.icon} />}
            />
          </Form.Item>
        </div>
        <Form.Item className={style.submit}>
          <Button
            className={style.submitSize}
            size='large'
            type='primary'
            htmlType='submit'
            loading={loading}
          >
            下一步
          </Button>
        </Form.Item>
      </div>
    </Form>
  );

  useEffect(() => {
    if (forgetPwd === false) loadCaptchaEnginge(6);
  }, [forgetPwd]);

  return (
    <Row justify='space-around' align='middle'>
      <Col xs={0} sm={0} md={0} lg={15} xl={15}>
        <img className={style.photo} src={LoginBg} alt='LoginBg'></img>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={9} className={style.inputForm}>
        {forgetPwd ? EmailForm : LoginForm}
      </Col>
    </Row>
  );
};

export default LoginPage;
