export const passwordHint =
  '密碼長度8個字元以上，包含英文大小寫、數字及特殊字元(~!@#$%^&*()_+)';
export const passwordJudge = [
  {
    required: true,
    message: '請確認密碼',
  },
  () => ({
    validator(_, value) {
      let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+])[\da-zA-Z~!@#$%^&*()_+]{8,16}$/;
      if (value && reg.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('密碼長度或內容不正確'));
    },
  }),
];
