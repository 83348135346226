import { fetchApi } from '../../services/ApiService';
import { putArticleSort } from '../../services/ArticleApi';

export const UpdataArticleSort = async (id, offset, from) => {
  const idName = `${from}_id`;
  let result = await fetchApi(
    putArticleSort,
    `?${idName}=${id}&offset=${offset}`,
    from
  );

  if (result.status === 200) {
    return true;
  } else {
    return false;
  }
};
