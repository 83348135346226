import { createContext, useRef } from 'react';
import { useEffect, useState } from 'react';
import ChangeIcon from '../ChangeIcon';
import { FetchCategory } from './TermHook/FetchCategory';
import { RemoveTerm } from './TermHook/RemoveTerm';
import DraggableTabs from '../../components/DraggableTabs';
import { TermSort } from './TermHook/TermSort';
import { useSearchParams } from 'react-router-dom';
import { EditingMode } from './EdittingMode';
import { ReadMode } from './ReadMode';
export const TermListContext = createContext();
const TermList = props => {
  const [category_list, setCategroy_list] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [adding, setAdding] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabKey, setTabKey] = useState('1');
  const [searchParams] = useSearchParams();
  let CategoryTerm = searchParams.get('CategoryTerm');
  const inputRef = useRef();
  useEffect(() => {
    getTermList();
  }, []);
  const getTermList = action => {
    FetchCategory().then(res => {
      setCategroy_list(res);
      if (CategoryTerm) {
        let key =
          action === 'del'
            ? res[res.length - 1]
            : adding
            ? res.find(income => income.index === Number(tabKey - 1))
            : res.find(income => income.label === CategoryTerm);
        props.setActiveKey(key);
        key && setTabKey(String(key.index + 1));
      } else {
        res ? props.setActiveKey(res[0]) : props.setActiveKey(null);
      }
    });
  };
  const add = () => {
    const newPanes = [...category_list];
    newPanes.push({
      label: '',
      value: '',
      term_id: category_list.length,
      key: category_list.length,
      index: category_list.length,
    });
    setCategroy_list(newPanes);
    setTabKey(String(category_list.length + 1));
    setEditingIndex(category_list.length);
  };

  const onChange = newActiveKey => {
    const target = category_list.find(
      pane => pane.index === Number(newActiveKey - 1)
    );
    target && props.setActiveKey(target);
    setTabKey(newActiveKey);
  };
  const ResetData = newArray => {
    let array = [];
    newArray.forEach((_, index) => {
      let obj = category_list.filter(
        v => v.index === Number(newArray[index] - 1)
      );
      array.push(obj[0]);
    });
    setCategroy_list(array);
    TermSort(array);
  };
  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      setAdding(p => !p);
      !adding && add();
    } else if (action === 'remove') {
      RemoveTerm(targetKey, category_list).then(res => {
        setCategroy_list(res);
        setTabKey(res.length - 1);
      });
    }
  };
  return (
    <TermListContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        setEditingIndex,
        setCategroy_list,
        setAdding,
        getTermList,
        setTabKey,
        adding,
        inputRef,
        category_list,
      }}
    >
      <DraggableTabs
        style={{ marginTop: 40, marginBottom: -40 }}
        size='large'
        type={'editable-card'}
        tabBarGutter={10}
        activeKey={tabKey}
        animated={true}
        ResetData={ResetData}
        onChange={newActiveKey => onChange(newActiveKey)}
        onEdit={(targetKey, action) => onEdit(targetKey, action)}
        items={category_list.map((tab, index) => ({
          key: String(tab.index + 1),
          id: tab.term_id,
          closable: false,
          term_id: tab.term_id,
          label:
            index === editingIndex ? (
              <EditingMode tab={tab} index={index} />
            ) : (
              <ReadMode tab={tab} index={index} />
            ),
        }))}
      />
      <ChangeIcon
        isModalOpen={isModalOpen}
        termInfro={category_list.find(
          term => String(term.index + 1) === tabKey
        )}
        getTermList={getTermList}
        onCancel={() => {
          setIsModalOpen(false);
          getTermList();
        }}
      />
    </TermListContext.Provider>
  );
};
export default TermList;
