import { fetchWithPage } from '../../services/ApiService';
import {
  getArticleDetailed,
  getArticleIntroduction,
} from '../../services/ArticleApi';
const FetchArticleDetailed = async (article_id, page, from) => {
  const res = await fetchWithPage(
    from === 'detail' ? getArticleDetailed : getArticleIntroduction,
    { pageSize: 3, current: page + 1 },
    article_id
  );
  return { list: res.data, page: page };
};
export default FetchArticleDetailed;
