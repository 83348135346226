import { Card, Col, Form, Row } from 'antd';
import ArticleHeader from './ArticleHeader';
import TermMenu from './TermMenu';
import { createContext, useContext, useMemo, useState } from 'react';
import ItemMenu from './ItemMenu';
import EditArea from './EditArea';
import {
  BasiceFormat_DataResource,
  OtherListFormat,
  PersonnellFormat,
} from './EditFormat';
import { useLocation, useNavigate } from 'react-router-dom';
import { FetchArticleData } from './ArticleEditHook/FetchArticleData';
import FetchArticleDetailed from './ArticleEditHook/FetchArticleDetailed';
import { PublicContext } from '../App';
export const ArticleContext = createContext();
const ArticleEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ArticleForm] = Form.useForm();
  const [termKey, setTermKey] = useState({});
  const [itemKey, setItemKey] = useState({});
  const [articleKey, setArticleKey] = useState(null);
  const [articleContent, setArticleContent] = useState({});
  const [loading, setLoading] = useState(false);
  const context = useContext(PublicContext);
  const { setPublicLoading } = context;

  const SetupFormDefault = (key, fetchData) => {
    if (key === 'tag_list') {
      let tagArray = fetchData[key].map(item => [
        item.tag_article_info.item_info.term_id,
        item.tag_article_info.item_id,
        item.tag_article_id,
      ]);
      ArticleForm.setFieldsValue({ tag_list: tagArray });
    } else if (key === 'item_id') {
      ArticleForm.setFieldsValue({
        item_id: [
          fetchData['item_info'].term_id,
          fetchData['item_info'].item_id,
        ],
      });
    } else if (key.includes('_list')) {
      if (fetchData[key].length > 0) {
        ArticleForm.setFieldsValue({ [key]: fetchData[key] });
      } else {
        let NewListFormat = [
          ...PersonnellFormat,
          ...OtherListFormat,
          ...BasiceFormat_DataResource,
        ].find(format => `${format.value}_list` === key);
        if (NewListFormat) {
          let insideObj = {};
          insideObj[NewListFormat.dataIndex] = '';
          NewListFormat.dataIndex2 &&
            (insideObj[NewListFormat.dataIndex2] = '');
          let FormDataObj = {};
          FormDataObj[key] = [insideObj];
          ArticleForm.setFieldsValue(FormDataObj);
        }
      }
    } else if (key === 'geom') {
      if (fetchData[key] && fetchData[key].coordinates)
        ArticleForm.setFieldsValue({
          longitude: String(fetchData[key].coordinates[0]),
          latitude: String(fetchData[key].coordinates[1]),
        });
      else ArticleForm.setFieldsValue({ longitude: null, latitude: null });
    }
  };
  const getArticleData = (articleKey, page, originalArray, from) => {
    FetchArticleDetailed(articleKey, page, from).then(detailed => {
      let fetchList =
        from === 'detail'
          ? detailed.list.detailList
          : detailed.list.IntroductionList;
      let newArray = [...originalArray, ...fetchList];
      if (detailed.list.rowsCount > newArray.length) {
        getArticleData(articleKey, detailed.page + 1, newArray, from);
      } else {
        articleContent[from + '_list'] = newArray;
        setArticleContent({ ...articleContent });
      }
      if (from === 'introduction') {
        setPublicLoading(false);
      }
    });
  };
  const getArticle = key => {
    FetchArticleData(key).then(fetchData => {
      ArticleForm.setFieldsValue(fetchData);
      Object.keys(fetchData).forEach(key => {
        SetupFormDefault(key, fetchData);
      });
      setLoading(false);
    });
  };
  useMemo(() => {
    if (articleKey) {
      setLoading(true);
      getArticle(articleKey);
      getArticleData(articleKey, 0, [], 'detail');
      getArticleData(articleKey, 0, [], 'introduction');
    } else {
      setTimeout(() => setPublicLoading(false), 1000);
    }
  }, [articleKey]);

  const ChangeUrlTab = (tab, status) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(status, tab);
    navigate({ search: `?${searchParams.toString()}` });
  };
  return (
    <Form form={ArticleForm}>
      <Card style={{ paddingLeft: 10, borderRadius: 0 }}>
        <ArticleHeader termName={termKey?.label} />
        <TermMenu
          setTermKey={key => {
            setTermKey(key);
            ChangeUrlTab(key?.label, 'Term');
          }}
          termKey={termKey?.term_id}
        />
      </Card>
      <Row gutter={10} style={{ margin: 8 }}>
        <Col span={4}>
          <ItemMenu
            termKey={termKey?.term_id}
            setItemKey={key => {
              setItemKey(key);
              ChangeUrlTab(key?.item_name, 'Item');
            }}
            itemKey={itemKey?.item_id}
          />
        </Col>
        <Col span={20}>
          <ArticleContext.Provider
            value={{
              articleContent,
              articleKey,
              ArticleForm: ArticleForm,
              getArticleData,
              getArticle,
            }}
          >
            <EditArea
              itemKey={itemKey?.item_id}
              itemName={itemKey?.item_name}
              setArticleKey={key => setArticleKey(key)}
              ChangeUrlTab={ChangeUrlTab}
              loading={loading}
            />
          </ArticleContext.Provider>
        </Col>
      </Row>
    </Form>
  );
};

export default ArticleEdit;
