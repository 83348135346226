import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Table,
  Typography,
  message,
  Popconfirm,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import NewAcc from './NewAcc';
import IBreadcrumb from '../Breadcrumb';
import { AccColums } from './AccColums';
import AccSearch from './AccSearch';
import { FetchInfro } from '../Hook/FetchInfro';
import { SendEmail } from './AccHook/SendEmail';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchApi, fetchWithPage } from '../services/ApiService';
import { delAcc, getAccListData } from '../services/AccApi';
import { PublicContext } from '../App';
const { Title, Text, Link } = Typography;

const AccList = () => {
  const navigate = useNavigate();
  const [accList, setAccList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowsCount, setRowsCount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const [Infro, setInfro] = useState();
  const [permission, setPermission] = useState(true);
  const context = useContext(PublicContext);
  const { setPublicLoading } = context;
  const key = 'AccList';
  const [searchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    current: Number(searchParams.get('page')) || 1,
    pageSize: 10,
  });
  let columns = [
    ...AccColums,
    {
      title: '動作',
      key: 'action',
      width: 280,
      render: (_, record) => (
        <Space size='middle'>
          <Link
            type='warning'
            onClick={() => {
              setEditData(record);
              setIsModalOpen(true);
            }}
          >
            修改帳號
          </Link>
          {Infro?.user_id !== record.user_id && (
            <Popconfirm
              title={`確定刪除${record.user_name}？`}
              onConfirm={() => DeleteAcc(record.user_id)}
              okText='確定'
              cancelText='取消'
            >
              <Link type='danger'>刪除帳號</Link>
            </Popconfirm>
          )}
          {record.account_status === 'nonactivated' && (
            <Link onClick={() => SendEmail(record.email)}>重寄郵件</Link>
          )}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    FetchAcc({ pagination });
    FetchInfro().then(res => {
      if (res) {
        setInfro(res);
      }
      if (res.permission !== 'administrator') {
        setPermission(false);
      }
    });
  }, []);
  const FetchAcc = async (params = {}) => {
    setLoading(true);
    const res = await fetchWithPage(getAccListData, params.pagination);
    searchParams.set('page', params.pagination.current);
    setPagination({
      ...params.pagination,
      total: res.data.rowsCount,
    });
    if (res.data.userList.length <= 0 && params.pagination.current > 1) {
      FetchAcc({
        pagination: {
          ...params.pagination,
          current: res.data.rowsCount / params.pagination.pageSize,
          total: res.data.rowsCount,
        },
      });
    } else {
      setAccList(
        res.data.userList.map((item, index) => ({
          key: index,
          ...item,
        }))
      );
      setRowsCount(res.data.rowsCount);
      setLoading(false);
      setPublicLoading(false);
    }
    navigate({ search: `?${searchParams.toString()}` });
  };

  const DeleteAcc = async user_id => {
    const res = await fetchApi(delAcc, user_id);
    if (res.status === 200) {
      message.success({ content: '已刪除', key });
      FetchAcc({ pagination });
    }
  };
  return (
    <>
      {!permission ?
        navigate('/PermissionDenied') :
        <>
          <Card style={{ padding: 10, borderRadius: 0, paddingTop: 0 }}>
            <IBreadcrumb />
            <Row justify='space-between' align='middle'>
              <Col>
                <Title level={3} style={{ marginTop: 20 }}>
                  帳號列表
                </Title>
                <Text>總共有{rowsCount}個帳號</Text>
              </Col>
              <Col>
                <Button type='primary' onClick={() => setIsModalOpen(true)}>
                  + 新增帳號
                </Button>
              </Col>
            </Row>
          </Card>
          <Card style={{ margin: 8 }}>
            <Row justify='space-between' style={{ marginBottom: 20 }}>
              <Col>
                <Text style={{ marginTop: 0 }}>所有帳號列表</Text>
              </Col>
              <Col>
                <AccSearch
                  pagination={pagination}
                  setPagination={setPagination}
                  setAccList={v => setAccList(v)}
                  setLoading={() => setLoading(p => !p)}
                />
              </Col>
            </Row>
            <Table
              loading={loading}
              dataSource={accList}
              columns={columns}
              pagination={pagination}
              scroll={{ x: 'max-content' }}
              onChange={pagination => {
                setPagination(pagination);
                FetchAcc({ pagination });
              }}
            />
          </Card>
          <NewAcc
            isModalOpen={isModalOpen}
            editData={editData}
            onCancel={() => {
              setIsModalOpen(false);
              FetchAcc({ pagination });
              setEditData(null);
            }}
          />
        </>
      }
    </>
  );
};
export default AccList;
