import { LockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Typography, message } from 'antd';
import React, { useState } from 'react';
import LoginBg from '../assets/LoginBg.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import style from './PasswordReset.module.scss';
import { passwordHint, passwordJudge } from '../components/passwordHint';
const { Title } = Typography;

const PasswordResetPage = () => {
  const [loading, setloading] = useState(false);
  const [pwdForm] = Form.useForm();
  const queryParameters = new URLSearchParams(window.location.search);
  const authCode = queryParameters.get('auth_code');
  const userId = queryParameters.get('user_id');
  const navigate = useNavigate();

  const onPwdFormFinish = async value => {
    setloading(true);
    if (value.password !== value.newPassword) {
      message.error('新密碼與確認新密碼內容不相符');
      setloading(false);
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin-api/account/${userId}`,
        {
          change_password_info: {
            password: value.password,
            auth_code: authCode,
          },
        }
      );

      if (res.status === 200) {
        message.success('完成修改');
        navigate('/login');
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error(
        error.response ? error.response.data.message : error.message
      );
    } finally {
      setloading(false);
    }
  };

  const PwdSettingForm = (
    <Form form={pwdForm} autoComplete='off' onFinish={onPwdFormFinish}>
      <div className={style.box} style={{ padding: '15% 25%' }}>
        <Form.Item className={style.header}>
          <LockOutlined className={style.titleIcon} />
          <Title level={3} className={style.title}>
            輸入新密碼
          </Title>
        </Form.Item>
        <Row>
          <Col span={24}>
            <p style={{ display: 'block' }}>新密碼</p>
          </Col>
        </Row>
        <Row>
          <Col className={style.inputCol}>
            <Form.Item
              name='password'
              style={{ width: '100%' }}
              extra={passwordHint}
              rules={passwordJudge}
            >
              <Input.Password
                size='large'
                placeholder='密碼'
                prefix={<LockOutlined className={style.icon} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={{ display: 'block' }}>確認新密碼</p>
          </Col>
        </Row>
        <Row>
          <Col className={style.inputCol}>
            <Form.Item
              name='newPassword'
              style={{ width: '100%' }}
              rules={[{ required: true, message: '請輸入密碼' }]}
            >
              <Input.Password
                size='large'
                placeholder='密碼'
                prefix={<LockOutlined className={style.icon} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button
              className={style.submitSize}
              size='large'
              type='primary'
              htmlType='submit'
              loading={loading}
              style={{ width: '100%', margin: '10px 0px' }}
            >
              確定
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );

  return (
    <Row justify='space-around' align='middle'>
      <Col xs={0} sm={0} md={0} lg={24} xl={15}>
        <img className={style.photo} src={LoginBg} alt='LoginBg'></img>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={9} className={style.inputForm}>
        {PwdSettingForm}
      </Col>
    </Row>
  );
};

export default PasswordResetPage;
