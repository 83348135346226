import { message } from 'antd';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Intro.module.scss';
import style from './Intro.module.scss';
import { ImageBlot, VideoBlot, Size, fontSize } from './QuillEditorSetting';

const Editor = props => {
  const { value, handleParams } = props;
  const [quillValue, setQuillValue] = useState(null);
  const quillRef = useRef(null);
  const key = 'Editor';

  ReactQuill.Quill.register(Size, true);
  ReactQuill.Quill.register(ImageBlot);
  ReactQuill.Quill.register(VideoBlot);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: fontSize }],
          [{ color: [] }],
          [{ background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['link', 'video', 'image'],
        ],
        handlers: {
          image() {
            mediaHandler();
          },
        },
      },
    }),
    []
  );

  function fixImageOrientation(image) {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(0); // 在這裡設置圖片旋轉的角度，通常是0度
        ctx.drawImage(
          img,
          -img.width / 2,
          -img.height / 2,
          img.width,
          img.height
        );
        ctx.restore();

        // 將處理過的圖片轉換為 Blob
        canvas.toBlob(blob => {
          resolve(blob);
        }, 'image/jpeg'); // 這裡可以根據您的需求調整圖片格式
      };

      img.src = URL.createObjectURL(image);
    });
  }

  const uploadFile = async (formData, token) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/admin-api/article/uploadFile`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  const updateImgAlt = async (alt, id, token) => {
    const formData = new FormData();
    formData.append('picture_alt', alt);

    return await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/admin-api/picture/` + id,
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  const updateVideoAlt = async (alt, id, token) => {
    const formData = new FormData();
    formData.append('video_alt', alt);

    return await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/admin-api/video/` + id,
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  const handleImage = async (name, id, token) => {
    const url = `${process.env.REACT_APP_API_URL}/article/` + name;
    const alt = prompt('請輸入圖片的替代文字:');
    const type = 'image';
    // await updateImgAlt(alt, id, token); // CORS PATCH
    return { url, alt, type };
  }

  const handleVideo = async (name, id, token) => {
    const url = `${process.env.REACT_APP_API_URL}/video/` + name;
    const alt = prompt('請輸入影片的替代文字:');
    const type = 'media';
    // await updateVideoAlt(alt, id, token); // CORS PATCH
    return { url, alt, type };
  }

  const handleMedia = async (resData, token) => {
    if (resData.picture_name)
      return await handleImage(resData.picture_name, resData.picture_id, token);

    if (resData.video_name)
      return await handleVideo(resData.video_name, resData.video_id, token);
  }

  const mediaHandler = () => {
    const token = localStorage.getItem('token');
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/* image/*');
    input.click();
    input.onchange = async () => {
      message.loading({ content: '加載中', key, duration: 0 });

      let file = input.files[0];
      if (input.files[0].type.includes('image')) {
        file = await fixImageOrientation(file);
      }

      const formData = new FormData();
      formData.append('article', file);

      const res = await uploadFile(formData, token);

      let quill = quillRef?.current?.getEditor();
      const cursorPosition = quill.getSelection().index;
      const { url, alt, type } = await handleMedia(res.data.data, token);

      quill.insertEmbed(cursorPosition, type, { alt: alt, src: url, title: alt });
      quill.setSelection(cursorPosition + 1);

      message.success({ content: '加載完成', key });
    };
  }

  const handleHtml = content => {
    setQuillValue(content);
    handleParams(content);
  };

  useEffect(() => {
    setQuillValue(value ? value : '');
  }, [value]);

  return (
    <ReactQuill
      ref={quillRef}
      theme='snow'
      modules={modules}
      value={quillValue}
      onChange={handleHtml}
      placeholder='請輸入文章内容'
      className={style.editor}
      style={{ height: '65vh', marginTop: 10 }}
      readOnly={props.readOnly}
    />
  );
};

export default Editor;
