import React, { createContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
export const PublicContext = createContext();

function App() {
  const [publicLoading, setPublicLoading] = useState(true);
  return (
    <BrowserRouter>
      <PublicContext.Provider value={{ publicLoading, setPublicLoading }}>
        <Router />
      </PublicContext.Provider>
    </BrowserRouter>
  );
}

export default App;
