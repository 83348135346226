import { Popconfirm, message } from 'antd';
import { DelArticleDetailed } from '../../ArticleEditHook/DelArticleDetailed';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
export const CardButton = (
  data,
  from,
  key,
  setIntroList,
  IntroList,
  setEditData,
  setIsModalOpen,
  readOnly
) => [
  <Popconfirm
    title='確定刪除?'
    onConfirm={() => {
      DelArticleDetailed(data[from + '_id'], from).then(res => {
        if (res) {
          message.success({ content: '刪除成功', key });
          let newArray = IntroList.filter(
            item => item[from + '_id'] !== data[from + '_id']
          );
          setIntroList(newArray);
        } else {
          message.error({ content: '刪除失敗', key });
        }
      });
    }}
    disabled={readOnly}
  >
    <DeleteOutlined key='del' />
  </Popconfirm>,
  <EditOutlined
    key='edit'
    onClick={() => {
      setEditData(data);
      setIsModalOpen(true);
    }}
  />,
];
