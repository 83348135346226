import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ArticleWarningTag } from './ArticleWarningTag';
const { Text } = Typography;
const ArticleFormList = ({ data, col, readOnly }) => {
  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Text type='secondary'>{ArticleWarningTag(data)} :</Text>
      </div>
      <Form.List name={data.value + '_list'}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row
                key={key}
                justify='space-between'
                style={{
                  display: 'flex',
                  marginBottom: 8,
                  width: '100%',
                }}
                align='baseline'
              >
                {data.note === 'link_name' && (
                  <Col span={8}>
                    <Form.Item {...restField} name={[name, data.dataIndex2]}>
                      <Input style={{ width: '100%' }} placeholder='名稱' readOnly={readOnly}/>
                    </Form.Item>
                  </Col>
                )}
                <Col span={col}>
                  <Form.Item {...restField} name={[name, data.dataIndex]}>
                    <Input
                      style={{ width: '100%' }}
                      placeholder={data.dataIndex === 'url' ? '網址' : ''}
                      readOnly={readOnly}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  {name === 0 ? (
                    <Button
                      shape='circle'
                      icon={<PlusOutlined style={{ fontSize: 12 }} />}
                      onClick={() => add()}
                      disabled={readOnly}
                    />
                  ) : (
                    <Button
                      shape='circle'
                      icon={<MinusOutlined style={{ fontSize: 12 }} />}
                      onClick={() => remove(name)}
                      disabled={readOnly}
                    />
                  )}
                </Col>
              </Row>
            ))}
          </>
        )}
      </Form.List>
    </>
  );
};
export default ArticleFormList;
