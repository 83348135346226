import { Col, Row, Typography } from 'antd';
import { PersonnellFormat } from '../EditFormat';
import ArticleFormList from '../../components/ArticleFormList';
const { Title } = Typography;
const RelatedPersonnel = ({ readOnly }) => {
  let dataFormat = PersonnellFormat;
  return (
    <>
      <Title level={5}>文章相關人員</Title>
      <Row justify='space-between' gutter={[32, 16]} style={{ marginTop: 20 }}>
        {dataFormat.map((data, index) => {
          return (
            <Col span={6} key={index}>
              <ArticleFormList data={data} col={20} readOnly={readOnly}/>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default RelatedPersonnel;
