import { fetchApi } from '../../../services/ApiService';
import { getCategory } from '../../../services/TermApi';
export const FetchCategory = async () => {
  try {
    const res = await fetchApi(getCategory);
    return res.data.map((item, index) => ({
      key: item.term_id,
      index: index,
      label: item.term_name,
      value: item.term_id,
      ...item,
    }));
  } catch (error) {
    return false;
  }
};
