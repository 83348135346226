import React from 'react';
import { Typography, Layout, Button, Tooltip, Row, Col, Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import PersonalInfro from './PersonalInfro';
import { useCookies } from 'react-cookie';
const { Header } = Layout;
const { Text } = Typography;

const Toolbar = () => {
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies();
  return (
    <Header style={{ backgroundColor: '#00422e' }}>
      <Row justify='space-between' align='middle'>
        <Col>
          <Text
            style={{
              color: '#fff',
              marginLeft: -30,
              fontWeight: 400,
              fontSize: 16,
              marginTop: -20,
            }}
          >
            台東大學資料庫
          </Text>
        </Col>
        <Col>
          <Space size='large'>
            <PersonalInfro />
            <Tooltip title='登出'>
              <Button
                style={{ backgroundColor: '#00422e', borderColor: '#00422e' }}
                type='primary'
                shape='circle'
                icon={<LogoutOutlined />}
                onClick={() => {
                  localStorage.removeItem('token');
                  removeCookie('token',{path:'/', domain: process.env.REACT_APP_DOMAIN_NAME});
                  navigate('/');
                }}
              />
            </Tooltip>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default Toolbar;
