import { Descriptions } from 'antd';
import { BasiceFormat, BasiceFormat_DataResource } from '../EditFormat';
import ArticleFormList from '../../components/ArticleFormList';
import BasiceInput from '../../components/BasiceInput';
import TagList from '../../components/TagList';
import { ArticleWarningTag } from '../../components/ArticleWarningTag';

const BasiceInfo = ({ readOnly }) => {
  const DataEntryMethod = data => {
    if (data.enterWay === 'Blank') {
      return <span />;
    }
    if (data.enterWay === 'Cascader') {
      return TagList(data.value, null, null, null, readOnly);
    } else {
      return BasiceInput(data.value, readOnly);
    }
  };
  return (
    <>
      <Descriptions title='文章基本資料' layout='vertical' column={4} >
        {BasiceFormat.map((data, index) => {
          return (
            <Descriptions.Item
              label={ArticleWarningTag(data)}
              key={index}
              style={{ width: '100%' }}
            >
              {DataEntryMethod(data)}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <ArticleFormList
        data={BasiceFormat_DataResource[0]}
        col={23}
        readOnly={readOnly}
      />
    </>
  );
};
export default BasiceInfo;
