import { UserOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useEffect, useState } from 'react';
import NewAcc from '../../Account/NewAcc';
import { FetchInfro } from '../../Hook/FetchInfro';
const { Text } = Typography;
const PersonalInfro = () => {
  const [Infro, setInfro] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    getInfrom();
  }, []);
  const getInfrom = () => {
    FetchInfro().then(res => {
      if (res) {
        setInfro(res);
      }
    });
  };
  return (
    <>
      <Button
        icon={<UserOutlined />}
        style={{
          backgroundColor: '#00422e',
          borderColor: '#00422e',
          color: 'white',
        }}
        onClick={() => setIsModalOpen(true)}
      >
        <Text style={{ color: 'white' }}>{Infro.user_name}</Text>
      </Button>
      <NewAcc
        from={'personal'}
        isModalOpen={isModalOpen}
        editData={Infro}
        onCancel={() => {
          setIsModalOpen(false);
          getInfrom();
        }}
      />
    </>
  );
};
export default PersonalInfro;
