import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
const { Content } = Layout;

const Main = () => {
  return (
    <Layout>
      <Content
        style={{
          margin: 0,
          minHeight: '100vh',
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
};

export default Main;
