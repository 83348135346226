import { Popconfirm, Space, Tag } from 'antd';

export const AccColums = [
  {
    title: '管理員編號',
    dataIndex: 'user_id',
    key: 'user_id',
  },
  {
    title: '管理員名稱',
    dataIndex: 'user_name',
    key: 'user_name',
  },
  {
    title: '權限',
    dataIndex: 'permission',
    key: 'permission',
    render: (_, { permission }) => {
      const permissionObj = {
        administrator: {
          color: 'green',
          label: '總管理員',
        },
        collector: {
          color: 'orange',
          label: '資料蒐集者',
        },
        editor: {
          color: 'geekblue',
          label: '編輯者',
        },
      };

      const settings = permissionObj[permission]

      return (
        <Tag color={settings.color} >
          {settings.label}
        </Tag >
      );
    },
  },
  {
    title: '帳號（電郵）',
    dataIndex: 'email',
    key: 'eamil',
  },
];
