import { useEffect, useState } from 'react';
import { FetchItem } from '../Category/ItemList/ItemHook/FetchItem';
import { Card, Empty, Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';

const ItemMenu = props => {
  const [itemList, setItemList] = useState([]);
  const [searchParams] = useSearchParams();
  let originalItem = searchParams.get('Item');
  useEffect(() => {
    props.termKey &&
      FetchItem(props.termKey).then(res => {
        if (res) {
          let key = res.find(income => income.label === originalItem);
          if (key) {
            props.setItemKey(key);
          } else {
            props.setItemKey(res[0]);
          }
          setItemList(res);
        } else {
          setItemList([]);
          props.setItemKey({});
        }
      });
  }, [props.termKey]);

  return (
    <Card
      style={{
        height: '100%',
        borderRadius: 0,
        width: '100%',
      }}
    >
      {itemList.length > 0 ? (
        <Tabs
          tabPosition='left'
          onTabScroll
          tabBarGutter={10}
          activeKey={props.itemKey}
          style={{ marginRight: -25 }}
          tabBarStyle={{ width: '100%' }}
          onChange={v => {
            let res = itemList.find(item => item.key === v);
            props.setItemKey(res);
          }}
          items={itemList}
        />
      ) : (
        <Empty />
      )}
    </Card>
  );
};
export default ItemMenu;
