import { Button, Input, Space } from 'antd';
import { EditTermInfo } from './TermHook/EditTermInfo';
import { TermListContext } from '.';
import { useContext, useState } from 'react';
import { handleComposition } from '../../components/handleComposition';
export const EditingMode = props => {
  const context = useContext(TermListContext);
  const [canEnter, setCanEnter] = useState(true);
  return (
    <Space size='small'>
      <Input
        key={props.tab.key}
        ref={context.inputRef}
        style={{ width: 100 }}
        placeholder={props.tab.label ? props.tab.label : '輸入後 Enter鍵新增'}
        onBlur={() => {
          if (context.adding) {
            const newTabs = [...context.category_list];
            newTabs.pop();
            context.setCategroy_list(newTabs);
          }
          context.setEditingIndex(null);
        }}
        autoFocus
        onPressEnter={() => {
          canEnter &&
            EditTermInfo(
              props.tab,
              context.adding,
              context.inputRef.current.input.value
            ).then(res => {
              if (res) {
                context.getTermList();
                context.setAdding(false);
                context.setEditingIndex(null);
              }
            });
        }}
        onCompositionStart={inputType =>
          handleComposition(inputType, setCanEnter)
        }
        onCompositionUpdate={inputType =>
          handleComposition(inputType, setCanEnter)
        }
        onCompositionEnd={inputType =>
          handleComposition(inputType, setCanEnter)
        }
      />
      <Button
        type='text'
        shape='circle'
        onChange={() => context.setEditingIndex(null)}
      >
        X
      </Button>
    </Space>
  );
};
