import { fetchApi, updataSort } from '../services/ApiService';

export const UpdataSort = async (id, offset, from) => {
  let result = await fetchApi(
    updataSort,
    from,
    `${from + '_id'}=${id}&offset=${offset}`
  );
  if (result.status === 200) {
    return true;
  } else {
    return false;
  }
};
