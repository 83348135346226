import { apiRequest } from './ApiService';

export const getCategory = () => apiRequest().get(`/term/list`);

export const editTermInfo = (body, path) =>
  apiRequest().put(`term/${path}`, { ...body });

export const postTermInfo = body => apiRequest().post(`/term`, { ...body });

export const delTerm = path => apiRequest().delete(`/term/${path}`);

export const putTermSort = body => apiRequest().put(`/term/order`, { ...body });
