import {
  Affix,
  Button,
  Input,
  Row,
  Col,
  Popconfirm,
  message,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { useEffect, useState, useRef, useContext } from 'react';
import { FetchItem } from './ItemHook/FetchItem';
import { EditItemInfo } from './ItemHook/EdtItemInfo';
import { RemoveItem } from './ItemHook/RemoveItem';
import DraggableTabs from '../../components/DraggableTabs';
import { useSearchParams } from 'react-router-dom';
import { ItemTabContext } from './ItemTabContext';
import { UpdataSort } from '../../Hook/UpdataSort';
import { SyncOutlined } from '@ant-design/icons';
import { FetchCategory } from '../TermList/TermHook/FetchCategory';
import { ChangeCategory } from '../../Hook/ChangeCategory';
import { handleComposition } from '../../components/handleComposition';
import { PublicContext } from '../../App';

const Item_List = props => {
  const [itemList, setItemList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [category_list, setCategroy_list] = useState([]);
  const [selectTerm, setSelectTerm] = useState();
  const [inputValue, setInputValue] = useState();
  const [canEnter, setCanEnter] = useState(true);
  const inputRef = useRef(null);
  const context = useContext(PublicContext);
  const { setPublicLoading } = context;
  const [searchParams] = useSearchParams();
  let CategoryItem = searchParams.get('CategoryItem');
  let key = 'Item_List';

  useEffect(() => {
    props.activeKey && getItem();
    FetchCategory().then(res => {
      setCategroy_list(res);
    });
  }, [props.activeKey]);
  const getItem = () => {
    FetchItem(props.activeKey).then(res => {
      setItemList(res);
      let key = res.find(income => income.label === CategoryItem);
      if (key) {
        props.setItemKey(key);
      } else {
        res ? props.setItemKey(res[0]) : props.setItemKey(null);
      }

      setPublicLoading(false);
    });
  };

  const onEdit = tab => {
    EditItemInfo(tab, inputValue, props.activeKey).then(res => {
      if (res.status) {
        getItem();
        setEditingIndex(-1);
        setInputValue();
      }
    });
  };
  const onChange = key => {
    const target = itemList.find(pane => pane.key === key);
    target && props.setItemKey(target);
  };
  const ResetData = (newArray, dragKey, hoverKey, itemID) => {
    UpdataSort(itemID, hoverKey - dragKey, 'item').then(res => {
      if (res) {
        message.success({ content: '更新成功', key });
        getItem();
      } else {
        message.error({ content: '更新失敗', key });
      }
    });
  };

  return (
    <>
      <DraggableTabs
        tabPosition='left'
        onTabScroll
        tabBarGutter={10}
        tabBarStyle={{ width: 250 }}
        onChange={onChange}
        ResetData={ResetData}
        activeKey={props.itemKey}
        tabBarExtraContent={
          <Input
            style={{ marginTop: itemList.length > 0 ? 30 : 0, width: 220 }}
            onChange={value => setInputValue(value.target.value)}
            value={editingIndex === -1 ? inputValue : ''}
            ref={inputRef}
            allowClear
            placeholder='輸入後 Enter鍵新增'
            onCompositionStart={inputType =>
              handleComposition(inputType, setCanEnter)
            }
            onCompositionUpdate={inputType =>
              handleComposition(inputType, setCanEnter)
            }
            onCompositionEnd={inputType =>
              handleComposition(inputType, setCanEnter)
            }
            onPressEnter={() => canEnter && onEdit()}
          />
        }
        items={itemList.map((tab, index) => ({
          key: tab.key,
          label: (
            <Row justify='space-between' align='middle' style={{ width: 200 }}>
              <ItemTabContext
                tab={tab}
                index={index}
                value={inputValue}
                setInputValue={setInputValue}
                inputRef={inputRef}
                setEditingIndex={setEditingIndex}
                onEdit={onEdit}
                editingIndex={editingIndex}
              />
              <Col span={11}>
                <Space align='center'>
                  <Popconfirm
                    placement="top"
                    title='更換第一大類'
                    okText='確定'
                    cancelText='取消'
                    description={
                      <Select
                        options={category_list}
                        onChange={v => setSelectTerm(v)}
                        defaultValue={props.activeKey}
                        style={{ width: '100%' }}
                      />
                    }
                    onConfirm={() =>
                      ChangeCategory(props.itemKey, selectTerm, 'item').then(
                        res => {
                          if (res) {
                            message.success('更新成功');
                            getItem();
                          }
                        }
                      )
                    }
                  >
                    <Button type='primary' ghost>
                      <SyncOutlined style={{ textAlign: 'center', width: 0 }} />
                    </Button>
                  </Popconfirm>
                  <Tooltip title='刪除'>
                    <Popconfirm
                      title='確定刪除？'
                      okText='確定'
                      cancelText='取消'
                      onConfirm={() =>
                        RemoveItem(tab, itemList).then(res => {
                          res && setItemList(res);
                          props.setItemKey(res[0]);
                        })
                      }
                    >
                      <Button shape='default' danger ghost>
                        X
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          ),
        }))}
      />
    </>
  );
};

export default Item_List;
