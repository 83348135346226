import { Col, Descriptions, Row } from 'antd';
import { OtherDataFormat, OtherListFormat } from '../EditFormat';
import ArticleFormList from '../../components/ArticleFormList';
import TagList from '../../components/TagList';
import { ArticleWarningTag } from '../../components/ArticleWarningTag';
import BasiceInput from '../../components/BasiceInput';

const OtherInfo = ({ readOnly }) => {
  const DataEntryMethod = data => {
    if (data.enterWay === 'Cascader') {
      return TagList(data.value + '_list', null, null, null, readOnly);
    } else {
      return BasiceInput(data.value, readOnly);
    }
  };
  return (
    <>
      <Descriptions title='其他資料' layout='vertical' column={2}>
        {OtherDataFormat.map((data, index) => {
          return (
            <Descriptions.Item label={ArticleWarningTag(data)} key={index}>
              {DataEntryMethod(data)}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Row gutter={16}>
        {OtherListFormat.map((data, index) => {
          return (
            <Col span={12} key={index}>
              <ArticleFormList data={data} col={14} readOnly={readOnly} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default OtherInfo;
