import { Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FetchInfro } from '../Hook/FetchInfro';
import { AccInputData } from './AccFormat';
import { AddNewAcc } from './AccHook/AddNewAcc';
import { passwordHint, passwordJudge } from '../components/passwordHint';

const NewAcc = props => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [Infro, setInfro] = useState([]);
  const [inputData, setInputData] = useState([]);
  let { editData, from } = props;

  useEffect(() => {
    if (props.editData) {
      form.setFieldsValue({
        ...props.editData,
      });
      FetchInfro().then(res => {
        if (res) {
          setInfro(res);
        }
      });
    }
    if (props.from === 'personal') {
      setInputData([
        ...AccInputData,
        {
          title: '新密碼 ',
          key: 'password',
        },
        {
          title: '確認新密碼',
          key: 'repw',
        },
      ]);
    } else {
      setInputData([...AccInputData]);
    }
  }, [props.editData]);

  return (
    <Modal
      title={editData ? '修改帳號' : '新增帳號'}
      open={props.isModalOpen}
      onCancel={() => {
        props.onCancel();
        form.resetFields();
      }}
      onOk={() => {
        form.validateFields().then(value => {
          AddNewAcc({
            data: {
              value,
              editData,
              originEditData: props.editData,
              from,
              form,
              Infro,
            },
            setData: { navigate, onCancel: props.onCancel },
          });
        });
      }}
    >
      <Form form={form} layout='vertical' style={{ marginTop: 30 }}>
        {inputData.map(item => {
          return item.key === 'password' ? (
            <Form.Item
              key={item.key}
              name={item.key}
              label={item.title}
              extra={passwordHint}
              rules={passwordJudge}
            >
              <Input.Password />
            </Form.Item>
          ) : item.key !== 'repw' ? (
            <Form.Item
              key={item.key}
              name={item.key}
              label={item.title}
              rules={[
                {
                  required: editData ? false : true,
                  message: '請確認' + item.title,
                },
              ]}
            >
              {item.key === 'permission' ? (
                <Select
                  disabled={
                    Infro?.user_name === editData?.user_name && editData
                  }
                  options={[
                    { label: '總管理員', value: 'administrator' },
                    { label: '編輯者', value: 'editor' },
                    { label: '資料蒐集者', value: 'collector' },
                  ]}
                />
              ) : (
                <Input />
              )}
            </Form.Item>
          ) : (
            <Form.Item
              key={item.key}
              name={item.key}
              label={item.title}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: editData ? false : true,
                  message: '請確認密碼',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('密碼不一致'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
};
export default NewAcc;
