import { fetchApi } from '../../services/ApiService';
import {
  editArticleDetailed,
  postArticleDetailed,
} from '../../services/ArticleApi';
export const EditArticleDetailed = async (id, newData, action, from) => {
  const res =
    action === 'put'
      ? await fetchApi(
          editArticleDetailed,
          {
            [from + '_info']: newData,
          },
          `${from}/${id}`
        )
      : await fetchApi(
          postArticleDetailed,
          {
            [from + '_info']: newData,
          },
          `${from}`
        );
  if (res.status === 200) {
    return { status: true, data: res.data[from + '_id'] };
  } else {
    return { status: false };
  }
};
