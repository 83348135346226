import { Space } from 'antd';

export const ArticleWarningTag = data => {
  return data.check ? (
    <Space size={3}>
      <div style={{ color: 'red' }}>* </div>
      {data.label}
    </Space>
  ) : (
    data.label
  );
};
