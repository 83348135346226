import { Button, Card, Empty, Popover, Space, Typography, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FetchArticle } from '../../Category/ArticleList/ArticleHook/FetchArticle';
import BasiceInfo from './BasiceInfo';
import RelatedPersonnel from './RelatedPersonnel';
import OtherInfo from './OtherInfo';
import Summary from './Summary';
import DetailedInfro from './DetailedInfro';
import { useCookies } from 'react-cookie';
import {
  CheckCircleTwoTone,
  EyeOutlined,
  CheckOutlined,
  CloudServerOutlined,
  EditTwoTone,
} from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { PutArticle } from '../ArticleEditHook/PutArticle';
import { ArticleContext } from '..';
import { FetchInfro } from '../../Hook/FetchInfro';
const { Text } = Typography;
const EditArea = props => {
  const [articleList, setArticleList] = useState([]);
  const [articleTabKey, setArticleTabKey] = useState();
  const [account_info, setAccount_info] = useState();
  const [readOnly, setReadOnly] = useState(false);
  const [searchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies();
  const ArticleParams = searchParams.get('Article');
  const context = useContext(ArticleContext);
  const token = localStorage.getItem('token');

  useEffect(() => {
    getArticle();
    FetchInfro().then(res => {
      setAccount_info(res);
      setReadOnly(res.permission === 'collector');
    });
  }, [props.itemKey]);

  const getArticle = () => {
    FetchArticle(props.itemKey).then(res => {
      if (res.length > 0) {
        setArticleList(
          res.map(item => ({
            tab: (
              <Popover
                content={
                  <Text style={{ color: item.warn ? 'red' : 'green' }}>
                    {item.warn ? '未完成' : '已完成'}
                  </Text>
                }
              >
                {item.visible === 'draft' ? (
                  <EditTwoTone twoToneColor='#d4b106' />
                ) : (
                  <CheckCircleTwoTone twoToneColor='#73d13d' />
                )}
                <Text style={{ color: item.warn ? 'red' : '#389e0d' }}>
                  {item.title}
                </Text>
              </Popover>
            ),
            label: item.title,
            key: item.article_id,
          }))
        );
        props.ChangeUrlTab(
          ArticleParams ? ArticleParams : res[0].title,
          'Article'
        );
        let ArticleId = res.find(item => item.title === ArticleParams);
        setArticleTabKey(ArticleId ? ArticleId.article_id : res[0].article_id);
        props.setArticleKey(
          ArticleId ? ArticleId.article_id : res[0].article_id
        );
      } else {
        setArticleList([]);
        props.ChangeUrlTab(null, 'Article');
        setArticleTabKey(null);
        props.setArticleKey(null);
      }
    });
  };
  const PostData = action => {
    PutArticle(
      context.ArticleForm.getFieldsValue(),
      action,
      articleTabKey
    ).then(result => {
      if (result.status) {
        message.success({ content: result.data });
        getArticle();
        context.getArticle(context.articleKey);
      } else {
        message.error({ content: result.data });
      }
    });
  };

  const redirectToDraftPage = () => {
    const options = {
      path: '/',
      domain: process.env.REACT_APP_DOMAIN_NAME
    };
    window.open(`${process.env.REACT_APP_ENTRY_WEB_URL}/DraftPage?articleId=${articleTabKey}`)
    setCookie('token', token, options);
  };

  return (
    <Card
      style={{ minHeight: '80vh', borderRadius: 0 }}
      tabList={articleList}
      activeTabKey={articleTabKey}
      loading={props.loading}
      title={props.itemName}
      extra={
        <Space>
          <Button
            type='primary'
            icon={<EyeOutlined />}
            onClick={() => redirectToDraftPage()}
          >
            預覽草稿
          </Button>
          <Button
            type='primary'
            ghost
            icon={<CloudServerOutlined />}
            onClick={() => PostData('draft')}
            disabled={
              account_info && account_info.permission === 'collector'
            }
          >
            草稿
          </Button>
          <Button
            type='primary'
            icon={<CheckOutlined />}
            disabled={
              account_info && account_info.permission !== 'administrator'
            }
            onClick={() => PostData('posted')}
          >
            發佈
          </Button>
        </Space>
      }
      onTabChange={tab => {
        let article_name = articleList.find(item => item.key === tab).label;
        props.ChangeUrlTab(article_name, 'Article');
        setArticleTabKey(tab);
        props.setArticleKey(tab);
      }}
    >
      {articleList.length > 0 ? (
        <>
          <BasiceInfo readOnly={readOnly}/>
          <RelatedPersonnel readOnly={readOnly}/>
          <OtherInfo readOnly={readOnly}/>
          <Summary readOnly={readOnly}/>
          <DetailedInfro readOnly={readOnly}/>
        </>
      ) : (
        <Empty />
      )}
    </Card>
  );
};
export default EditArea;
