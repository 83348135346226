import { Button, Card, Input, List, Modal } from 'antd';
import { useContext, useState } from 'react';
import { TermListContext } from './TermList';
import * as icons from 'react-bootstrap-icons';
import { EditTermInfo } from './TermList/TermHook/EditTermInfo';
const ChangeIcon = props => {
  const iconNames = Object.keys(icons);
  const context = useContext(TermListContext);
  const [IconList, setIconList] = useState(iconNames.slice(0, 24));
  const [searchValue, setSearchValue] = useState();
  const [selectValue, setSelectValue] = useState();
  const onSearch = searchItem => {
    setSearchValue(searchItem);
    searchItem
      ? setIconList(
          iconNames
            .filter(name => name.toLowerCase().includes(searchItem))
            .slice(0, 24)
        )
      : setIconList(iconNames.slice(0, 24));
  };
  const LoadMore = IconList.length % 3 === 0 && (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button
        onClick={() => {
          if (searchValue) {
            let searchArray = iconNames.filter(name =>
              name.toLowerCase().includes(searchValue)
            );
            setIconList([
              ...IconList,
              ...searchArray.slice(IconList.length, IconList.length + 24),
            ]);
          } else {
            setIconList([
              ...IconList,
              ...iconNames.slice(IconList.length, IconList.length + 24),
            ]);
          }
        }}
      >
        加載更多
      </Button>
    </div>
  );

  return (
    <Modal
      title='Basic Modal'
      open={props.isModalOpen}
      onOk={() =>
        EditTermInfo(
          { ...props.termInfro, icon: selectValue },
          false,
          props.termInfro.term_name
        ).then(res => {
          if (res) {
            props.onCancel();
            onSearch(null);
            setSelectValue(null);
            context.setAdding(false);
            context.setEditingIndex(null);
          }
        })
      }
      onCancel={() => {
        props.onCancel();
        onSearch(null);
        setSelectValue(null);
      }}
      width={1000}
    >
      <br />
      <Input.Search
        size='large'
        placeholder='搜尋icon'
        enterButton
        value={searchValue}
        onChange={value => {
          onSearch(value.target.value);
        }}
        onSearch={value => {
          onSearch(value);
        }}
      />
      <br />
      <br />
      <List
        grid={{
          gutter: 16,
          xs: 3,
          sm: 3,
          md: 6,
          lg: 6,
          xl: 6,
          xxl: 6,
        }}
        dataSource={IconList}
        loadMore={LoadMore}
        renderItem={IconNAME => {
          const BootstrapIcon = icons[IconNAME];
          return (
            <List.Item style={{ height: 120 }}>
              <Card
                hoverable={true}
                onClick={() => setSelectValue(IconNAME)}
                style={{
                  textAlign: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  backgroundColor:
                    selectValue === IconNAME
                      ? '#b7eb8f'
                      : IconNAME === props.termInfro.icon
                      ? '#ebf2e4'
                      : 'white',
                }}
              >
                <BootstrapIcon style={{ fontSize: 25 }} />
                <div>{IconNAME}</div>
              </Card>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default ChangeIcon;
