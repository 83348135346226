import { message } from 'antd';
import { fetchApi } from '../../../services/ApiService';
import { delArticle } from '../../../services/ArticleApi';
export const RemoveArticle = async (targetKey, articleList) => {
  let key = 'RemoveArticle';
  const res = await fetchApi(delArticle, targetKey);
  if (res.status === 200) {
    message.success({ content: '已刪除', key });
    return articleList.filter(pane => pane.article_id !== targetKey);
  }
};
