import { useNavigate } from 'react-router-dom';
import IBreadcrumb from '../Breadcrumb';
import { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { FetchInfro } from '../Hook/FetchInfro';

const { Title } = Typography;
const ArticleHeader = props => {
  const navigate = useNavigate();
  const [account_info, setAccount_info] = useState();

  useEffect(() => {
    FetchInfro().then(res => { setAccount_info(res); });
  }, [props.itemKey]);

  return (
    <>
      <IBreadcrumb />
      <Row style={{ marginTop: 5 }} justify='space-between' align='middle'>
        <Col>
          <Title level={3}>文化及在地產物內容編輯</Title>
        </Col>
        <Col>
          <Button
            onClick={() => navigate(`/Category?CategoryTerm=${props.termName}`)}
            disabled={account_info && account_info.permission === 'collector'}
          >
            跳轉類別管理 - {props.termName}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ArticleHeader;
