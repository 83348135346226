import { Card, Empty, Space, Typography } from 'antd';
import { useState, useEffect } from 'react';
import Item_List from './ItemList';
import IBreadcrumb from '../Breadcrumb';
import TermList from './TermList';
import ArticleList from './ArticleList';
import { useLocation, useNavigate } from 'react-router-dom';
import { FetchInfro } from '../Hook/FetchInfro';

const { Title } = Typography;

const Category = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(null);
  const [itemKey, setItemKey] = useState(null);
  const [permission, setPermission] = useState(true);
  const ChangeUrlTab = (tab, status) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(status, tab);
    navigate({ search: `?${searchParams.toString()}` });
  };

  useEffect(() => {
    FetchInfro().then(res => {
      if (res.permission !== 'administrator' && res.permission !== 'editor') {
        setPermission(false);
      }
    })
  }, [])

  return (
    <>
      {!permission ?
        navigate('/PermissionDenied') :
        < div >
          <Card style={{ paddingLeft: 10, borderRadius: 0 }}>
            <IBreadcrumb />
            <Title level={3} style={{ marginTop: 10 }}>
              類別管理
            </Title>
            <TermList
              setActiveKey={term => {
                setActiveKey(term?.term_id);
                ChangeUrlTab(term?.term_name, 'CategoryTerm');
              }}
              activeKey={activeKey}
            />
          </Card>
          <Card style={{ margin: 8, minHeight: '80vh', borderRadius: 0 }}>
            <Space align='start'>
              <Item_List
                activeKey={activeKey}
                setItemKey={item => {
                  setItemKey(item?.item_id);
                  ChangeUrlTab(item?.item_name, 'CategoryItem');
                }}
                itemKey={itemKey}
              />
              {itemKey ? (
                <ArticleList itemKey={itemKey} />
              ) : (
                <Empty style={{ width: '100%' }} />
              )}
            </Space>
          </Card>
        </div >
      }
    </>
  );
};
export default Category;
