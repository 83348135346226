import { apiRequest } from './ApiService';

export const delArticleDetailed = path => apiRequest().delete(`/${path}`);

export const editArticleDetailed = (body, path) =>
  apiRequest().put(`/${path}`, { ...body });

export const postArticleDetailed = (body, path) =>
  apiRequest().post(`/${path}`, { ...body });

export const getArticleData = path => apiRequest().get(`/article/${path}`);

export const getArticleDetailed = (param, path) =>
  apiRequest().get(`/detail/list/${path}`, { params: { ...param } });

export const getArticleIntroduction = (param, path) =>
  apiRequest().get(`/introduction/list/${path}`, { params: { ...param } });

export const editArticle = (body, path) =>
  apiRequest().put(`/article/${path}`, { ...body });

export const putArticleSort = (body, path) =>
  apiRequest().put(`/${path}/order${body}`);

export const delArticle = path => apiRequest().delete(`/article/${path}`);
