import { Input, Modal, Typography } from 'antd';
import { ModalContext } from '.';
import { useContext, useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import Editor from './Editor';
const { Text } = Typography;

const IntroModal = ({ readOnly }) => {
  const context = useContext(ModalContext);
  const [title, setTitle] = useState(null);
  const [quillValue, setQuillValue] = useState(null);
  let EditData = context.EditData;
  useEffect(() => {
    if (EditData) {
      setQuillValue(EditData['description']);
      setTitle(EditData['headline']);
    } else {
      setQuillValue(null);
      setTitle(null);
    }
  }, [EditData]);

  return (
    <Modal
      title='新增'
      open={context.isModalOpen}
      style={{ top: 10 }}
      bodyStyle={{ paddingTop: 10, paddingBottom: 60 }}
      width={'90vw'}
      onCancel={() => {
        context.setIsModalOpen(false);
        // setQuillValue(null);
        // setTitle(null);
      }}
      onOk={() => {
        let id =
          context.from === 'detail'
            ? EditData?.detail_id
            : EditData?.introduction_id;
        context.EdittingData(
          {
            headline: title || '',
            description: quillValue,
            [context.from + '_id']: id ? id : null,
          },
          context.EditData ? 'edit' : 'add'
        );
        context.setIsModalOpen(false);
        setQuillValue('');
        setTitle(null);
      }}
      okButtonProps={{ disabled: readOnly }}
      cancelButtonProps={{ disabled: readOnly }}
    >
      <Text style={{ fontSize: 16 }}>編輯卡名稱</Text>
      <Input
        value={title}
        style={{ marginBottom: 10, marginTop: 10 }}
        placeholder='請輸入編輯卡名稱'
        onChange={v => {
          setTitle(v.target.value);
        }}
        readOnly={readOnly}
      />
      <Text style={{ fontSize: 16 }}>內容</Text>
      <Editor value={quillValue} handleParams={v => setQuillValue(v)} readOnly={readOnly}/>
    </Modal>
  );
};
export default IntroModal;
