import { message } from 'antd';
import { EditArticleDetailed } from '../../ArticleEditHook/EditArticleDetailed';

export const getEditData = (
  newData,
  status,
  IntroList,
  setIntroList,
  setData,
  article_id,
  from,
  getArticleData,
  articleKey
) => {
  let key = 'getEditData';
  let newList = IntroList;
  message.loading({ content: '處理中', key });
  if (status === 'edit') {
    let changeIndex = IntroList.findIndex(
      item => item[from + '_id'] === newData[from + '_id']
    );
    EditArticleDetailed(
      from === 'detail' ? newData.detail_id : newData.introduction_id,
      { headline: newData.headline, description: newData.description },
      'put',
      from
    ).then(res => {
      if (res.status) {
        message.success({ content: '編輯成功', key });
        newList[changeIndex] = { ...newData };
        getArticleData(articleKey, 0, [], from);
        setIntroList(newList);
        setData(newList);
      } else {
        message.error({ content: '編輯失敗', key });
      }
    });
  } else if (status === 'add') {
    let postData = { ...newData, article_id: article_id };
    delete postData[from + '_id'];
    EditArticleDetailed('', postData, 'post', from).then(res => {
      if (res.status) {
        message.success({ content: '新增成功', key });
        setIntroList([...IntroList, { ...newData, [from + '_id']: res.data }]);
        setData([...IntroList, { ...newData, [from + '_id']: res.data }]);
      } else {
        message.error({ content: '新增失敗', key });
      }
    });
  }
};
