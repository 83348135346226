import ReactQuill from 'react-quill';


const BlockEmbed = ReactQuill.Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute('alt', value.alt);
    node.setAttribute('src', value.src);
    node.setAttribute('title', value.alt);
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      src: node.getAttribute('src'),
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';


class VideoBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute('alt', value.alt);
    node.setAttribute('src', value.src);
    node.setAttribute('title', value.alt);
    node.setAttribute('controls', 'true');
    node.setAttribute('type', 'video/*');

    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      src: node.getAttribute('src'),
    };
  }
}
VideoBlot.blotName = 'media';
VideoBlot.tagName = 'video';


export const fontSize = [
  '12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '32px', '48px',
];
const Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = fontSize;


export { ImageBlot, VideoBlot, Size };