export const handleComposition = (inputType, setCanEnter) => {
  if (
    inputType.type === 'compositionupdate' ||
    inputType.type === 'compositionstart'
  ) {
    setCanEnter(false);
  } else if (inputType.type === 'compositionend') {
    setCanEnter(true);
  }
};
