import { apiRequest } from './ApiService';

export const postItemInfo = body => apiRequest().post(`/item`, { ...body });

export const editItemInfo = (body, params) =>
  apiRequest().put(`/item/${params}`, { ...body });

export const getItemData = (params, path) =>
  apiRequest().get(`/item/list/${path}`, { params: params });

export const delItem = path => apiRequest().delete(`/item/${path}`);
