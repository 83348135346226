import { message } from 'antd';
import axios from 'axios';

export const EditArticle = async (
  article_id,
  inputRef,
  item_id,
  incoming_key
) => {
  const token = localStorage.getItem('token');
  const value = inputRef.current ? inputRef.current.input.value : inputRef;
  let key = incoming_key || 'EditItemInfo';
  const data = {
    item_id: item_id,
    title: value,
  };

  !article_id && (data['visible'] = 'draft');
  const action = !article_id ? 'post' : 'patch';
  const url = !article_id
    ? '/admin-api/article'
    : `/admin-api/article/${article_id}`;
  try {
    const res = await axios({
      method: action,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      data: article_id ? data : { article_info: { ...data } },
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      message.success({ content: !article_id ? '新增成功' : '修改成功', key });
      let newObj = !article_id
        ? {
          title: value,
          article_id: res.data.data.article_id,
          item_id: item_id,
          key: res.data.data.article_id,
          visible: 'draft',
          warn: true,
        }
        : { title: value };
      return { newObj, status: true };
    } else {
      message.error({ content: !article_id ? '新增失敗' : '修改失敗', key });
      return { status: false };
    }
  } catch (err) {
    message.error({ content: err.response.data.message, key });
  }
};
