import { useCallback, useEffect, useRef, useState } from 'react';
import { FetchArticle } from './ArticleHook/FetchArticle';
import { Card, Col, Form, Input, Popconfirm, Row, message } from 'antd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { EditArticle } from './ArticleHook/EditArticle';
import { ReactDndDragSort } from './SortableCardList';
import {
  CheckCircleTwoTone,
  ControlOutlined,
  DeleteOutlined,
  EditTwoTone,
  SettingOutlined,
} from '@ant-design/icons';
import { RemoveArticle } from './ArticleHook/RemoveArticle';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UpdataSort } from '../../Hook/UpdataSort';
import TagList from '../../components/TagList';
import { ChangeCategory } from '../../Hook/ChangeCategory';
import { handleComposition } from '../../components/handleComposition';

const ArticleList = props => {
  const navigate = useNavigate();
  const [articleList, setArticleList] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editingValue, setEditingValue] = useState('');
  const [editingIndex, setEditingIndex] = useState('');
  const [searchParams] = useSearchParams();
  const [canEnter, setCanEnter] = useState(true);
  const timeout = useRef();
  let ItemName = searchParams.get('CategoryItem');
  let TermName = searchParams.get('CategoryTerm');
  const inputRef = useRef(null);
  let [form] = Form.useForm();
  let key = 'ArticleList';
  useEffect(() => {
    props.itemKey ? getArticle() : setArticleList([]);
  }, [props.itemKey]);

  const getArticle = () => {
    FetchArticle(props.itemKey).then(res => {
      setArticleList(res ? res : []);
    });
  };
  const moveCard = useCallback(
    (sourceIndex, destinationIndex) => {
      const updatedCards = [...articleList];
      UpdataSort(
        updatedCards[sourceIndex]['article_id'],
        destinationIndex - sourceIndex,
        'article'
      ).then(res => {
        if (res) {
          message.success({ content: '已更新', key });
          const [movedCard] = updatedCards.splice(sourceIndex, 1);
          updatedCards.splice(destinationIndex, 0, movedCard);
          setArticleList(updatedCards);
        } else {
          message.error({ content: '更新失敗', key });
        }
      });
    },
    [articleList]
  );
  const removeCard = id => {
    RemoveArticle(id, articleList).then(res => {
      setArticleList(res);
    });
  };
  const addCard = (key, inputRef, item_id, index) => {
    let res = EditArticle(key, inputRef, item_id).then(res => {
      if (res.status) {
        const newArr = [...articleList];
        newArr[index] = {
          ...articleList[index],
          ...res.newObj,
        };
        setArticleList(newArr);
        return true;
      }
    });
    return res;
  };

  return (
    <Form form={form}>
      <Row gutter={[16, 16]}>
        <DndProvider backend={HTML5Backend}>
          {articleList.map((card, index) => (
            <Col key={card.key}>
              <ReactDndDragSort
                moveRow={moveCard}
                index={index}
                key={card.key}
                accept='ArticleList'
              >
                <Card
                  style={{ width: 180 }}
                  hoverable={true}
                  actions={[
                    <Popconfirm
                      title='確定刪除？'
                      onConfirm={() => removeCard(card.key)}
                      okText='確定'
                      cancelText='取消'
                    >
                      <DeleteOutlined key='delete' />
                    </Popconfirm>,
                    <Popconfirm
                      placement="top"
                      title='更換第二大類'
                      description={() =>
                        TagList('item', TermName, card.item_id, form)
                      }
                      onConfirm={() => {
                        ChangeCategory(
                          card.key,
                          form.getFieldsValue().item[1],
                          'article'
                        ).then(res => {
                          if (res) {
                            getArticle();
                            message.success('更新成功');
                          }
                          form.resetFields();
                        });
                      }}
                      okText='確定'
                      cancelText='取消'
                    >
                      <SettingOutlined key='edit' />
                    </Popconfirm>,
                    <ControlOutlined
                      key='ellipsis'
                      onClick={() =>
                        navigate(
                          `/Article?Article=${card.title}&Item=${ItemName}&Term=${TermName}`
                        )
                      }
                    />,
                  ]}
                >
                  {index === editingIndex ? (
                    <Input
                      ref={inputRef}
                      autoFocus
                      onBlur={() => setEditingIndex(null)}
                      value={editingValue}
                      onChange={value => setEditingValue(value.target.value)}
                      onCompositionStart={inputType =>
                        handleComposition(inputType, setCanEnter)
                      }
                      onCompositionUpdate={inputType =>
                        handleComposition(inputType, setCanEnter)
                      }
                      onCompositionEnd={inputType =>
                        handleComposition(inputType, setCanEnter)
                      }
                      onPressEnter={() => {
                        if (canEnter) {
                          let res = addCard(
                            card.key,
                            editingValue,
                            card.item_id,
                            index
                          );
                          res && setEditingIndex(null);
                        }
                      }}
                    />
                  ) : (
                    <div
                      onDoubleClick={() => {
                        setEditingIndex(index);
                        setEditingValue(card.title);
                      }}
                      style={{ color: card.warn ? 'red' : '#52c41a' }}
                    >
                      {card.visible === 'draft' ? (
                        <EditTwoTone twoToneColor='#d4b106' />
                      ) : (
                        <CheckCircleTwoTone twoToneColor='#52c41a' />
                      )}{' '}
                      {card.title}
                    </div>
                  )}
                </Card>
              </ReactDndDragSort>
            </Col>
          ))}
        </DndProvider>
        <Col>
          <Card style={{ width: 180 }}>
            <Input
              bordered={false}
              ref={inputRef}
              placeholder='輸入後 Enter鍵新增'
              style={{ height: 70 }}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onCompositionStart={inputType =>
                handleComposition(inputType, setCanEnter)
              }
              onCompositionUpdate={inputType =>
                handleComposition(inputType, setCanEnter)
              }
              onCompositionEnd={inputType => {
                handleComposition(inputType, setCanEnter);
              }}
              onPressEnter={() => {
                if (canEnter && inputValue) {
                  message.loading({ content: '新增中', key });

                  if (timeout.current) {
                    clearTimeout(timeout.current);
                  }
                  timeout.current = setTimeout(() => {
                    EditArticle(null, inputRef, props.itemKey, key).then(
                      res => {
                        if (res.status) {
                          const newTabs = [...articleList];
                          const i = articleList.length;
                          newTabs[i] = {
                            ...articleList[i],
                            ...res.newObj,
                          };
                          setArticleList(newTabs);
                          setInputValue('');
                        }
                      }
                    );
                  }, 500);
                }
              }}
            />
          </Card>
        </Col>
      </Row>
    </Form>
  );
};
export default ArticleList;
