import { Tabs } from 'antd';
import { useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const DraggableTabNode = ({ index, children, moveNode }) => {
  const ref = useRef(null);
  const type = 'DraggableTabNode';
  // 使用 useDrop 實現拖放
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: 'dropping',
      };
    },
    drop: item => {
      moveNode(item.index, index);
    },
  });
  // 使用 useDrag 實現拖拽
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <div ref={ref} className={isOver ? dropClassName : ''}>
      {children}
    </div>
  );
};
const DraggableTabs = props => {
  const { items = [] } = props;
  const [order, setOrder] = useState([]);
  const moveTabNode = (dragKey, hoverKey) => {
    const newOrder = [];
    items.forEach(item => {
      if (item.key && newOrder.indexOf(item.key) === -1) {
        newOrder.push(item.key);
      }
    });
    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);
    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);
    setOrder(newOrder);
    props?.ResetData(newOrder, dragIndex, hoverIndex, dragKey);
  };
  const renderTabBar = (tabBarProps, DefaultTabBar) => (
    <DefaultTabBar {...tabBarProps}>
      {node => (
        <DraggableTabNode
          key={node.key}
          index={node.key}
          moveNode={moveTabNode}
        >
          {node}
        </DraggableTabNode>
      )}
    </DefaultTabBar>
  );
  // 對 items 進行排序以匹配 order 的順序
  const orderItems = [...items].sort((a, b) => {
    const orderA = order.indexOf(a.key);
    const orderB = order.indexOf(b.key);
    if (orderA !== -1 && orderB !== -1) {
      return orderA - orderB;
    }
    if (orderA !== -1) {
      return -1;
    }
    if (orderB !== -1) {
      return 1;
    }
    const ia = items.indexOf(a);
    const ib = items.indexOf(b);
    return ia - ib;
  });
  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs
        renderTabBar={renderTabBar}
        {...props}
        items={orderItems}
        ResetData={props.ResetData}
      />
    </DndProvider>
  );
};
export default DraggableTabs;
