import { message } from 'antd';
import { fetchApi } from '../../../services/ApiService';
import { delTerm } from '../../../services/TermApi';
export const RemoveTerm = async (targetKey, category_list) => {
  let key = 'RemoveTerm';
  const res = await fetchApi(delTerm, targetKey.term_id);
  if (res.status === 200) {
    message.success({ content: '已刪除', key });
    return category_list.filter(pane => pane.term_id !== targetKey.term_id);
  }
};
