import { fetchWithPage } from '../../../services/ApiService';
import { getItemData } from '../../../services/ItemApi';

export const FetchItem = async activeKey => {
  const res = await fetchWithPage(
    getItemData,
    { current: 1, pageSize: 100 },
    activeKey
  );
  let result = res.data.itemList.map(item => ({
    key: item.item_id,
    label: item.item_name,
    value: item.item_name,
    ...item,
  }));
  return result || [];
};
