import { Result } from 'antd';

function PermissionDenied() {
  return (
    <Result
      style={{ marginTop: 80 }}
      status='403'
      title='403'
      subTitle='您無權限瀏覽此頁'
    />
  );
}

export default PermissionDenied