import { Tabs } from 'antd';
import { FetchCategory } from '../Category/TermList/TermHook/FetchCategory';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const TermMenu = props => {
  const [categroy_list, setCategroy_list] = useState([]);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    FetchCategory().then(res => {
      if (searchParams.get('Term')) {
        let key = res.find(income => income.label === searchParams.get('Term'));
        props.setTermKey(key);
      } else {
        props.setTermKey(res[0]);
      }
      setCategroy_list(res);
    });
  }, []);

  return (
    <Tabs
      items={categroy_list}
      type='card'
      size='large'
      tabBarGutter={10}
      activeKey={props.termKey}
      animated={true}
      onChange={v => {
        let res = categroy_list.find(item => item.key === v);
        props.setTermKey(res);
      }}
      style={{ marginTop: 20, marginBottom: -40 }}
    />
  );
};

export default TermMenu;
