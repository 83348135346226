import { message } from 'antd';
import { fetchApi } from '../../../services/ApiService';
import { delItem } from '../../../services/ItemApi';
export const RemoveItem = async (tab, itemList) => {
  const target = itemList.find(pane => pane.key === tab.key);
  let key = 'RemoveItem';
  const res = await fetchApi(delItem, target.item_id);
  if (res.status === 200) {
    message.success({ content: '已刪除', key });
    const newPanes = itemList.filter(pane => pane.key !== tab.key);
    return newPanes;
  }
};
