import {
  DatabaseOutlined,
  UserOutlined,
  EditOutlined,
} from '@ant-design/icons';

export const SiderMenuFormat = [
  {
    label: '帳號管理',
    key: '/Account',
    icon: <UserOutlined />,
  },
  {
    label: '類別管理',
    key: '/Category',
    icon: <DatabaseOutlined />,
  },
  {
    label: '文化及在地產物內容編輯',
    key: '/Article',
    icon: <EditOutlined />,
  },
];
