export const AccInputData = [
  {
    title: '姓名',
    key: 'user_name',
  },
  {
    title: '帳號（電郵）',
    key: 'email',
  },
  {
    title: '權限',
    key: 'permission',
  },
];
