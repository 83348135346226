import { useContext, useEffect, useState } from 'react';
import IntroCard from './IntroCard';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ArticleContext } from '..';
const DetailedInfro = ({ readOnly }) => {
  const [DetailedList, setDetailedList] = useState(null);
  const context = useContext(ArticleContext);
  useEffect(() => {
    if (context.articleContent.detail_list) {
      setDetailedList(context.articleContent.detail_list);
    }
  }, [context.articleContent]);
  return (
    <DndProvider backend={HTML5Backend}>
      <IntroCard
        from='detail'
        data={DetailedList}
        setData={setDetailedList}
        title={'詳細介紹（點選「查看更多」後顯示）'}
        article_id={context.articleKey}
        readOnly={readOnly}
      />
    </DndProvider>
  );
};
export default DetailedInfro;
