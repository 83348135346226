import { fetchApi } from '../../services/ApiService';
import { editArticle } from '../../services/ArticleApi';

export const PutArticle = async (data, action, id) => {
  let putObj = { ...data };
  Object.keys(putObj).map(key => {
    if (key.includes('_list') && key !== 'tag_list') {
      let check = false;
      Object.keys(putObj[key][0]).map(
        objKey => (check = putObj[key][0][objKey] === '' ? true : false)
      );
      check && (putObj[key] = []);
    }
  });

  ['longitude', 'latitude'].map(key => {
    if (putObj[key] === '') putObj[key] = null;
  });
  if (
    (!putObj['longitude'] || !putObj['latitude']) &&
    !(!putObj['longitude'] && !putObj['latitude'])
  ) {
    return { status: false, data: '更新失敗，不允許經度和緯度的其中一欄為空' };
  }

  putObj['item_id'] = putObj['item_id'][1];
  let tag_list = [];
  data['tag_list'].length > 0 &&
    data['tag_list'].map((tagItem, index) => {
      tag_list.push({
        tag_article_id: data['tag_list'][index][tagItem.length - 1],
      });
    });
  delete putObj['tag_list'];
  putObj['visible'] = action;

  const res = await fetchApi(
    editArticle,
    { article_info: { ...putObj, tag_list: tag_list } },
    id
  );
  if (res.status === 200) {
    return { status: true, data: res.data };
  } else {
    return { status: false, data: res.data.message };
  }
};
