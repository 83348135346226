import { Form, Input } from 'antd';

const BasiceInput = (formName, readOnly) => {
  return (
    <Form.Item name={formName} noStyle>
      <Input style={{ marginRight: 10, width: '100%' }} readOnly={readOnly}/>
    </Form.Item>
  );
};
export default BasiceInput;
