import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Row, Space, Typography } from 'antd';
import {
  useState,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import IntroModal from './IntroModal';
import style from './Intro.module.scss';
import { ReactDndDragSort } from './SortIntroCard';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { getEditData } from './getEditData';
import { CardButton } from './CardButton';
import MoveRow from './moveRow';
import { ArticleContext } from '../..';
export const ModalContext = createContext();
const { Title } = Typography;
const IntroCard = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [IntroList, setIntroList] = useState([]);
  const [EditData, setEditData] = useState();
  const context = useContext(ArticleContext);
  let from = props.from;
  let key = 'IntroCard';
  let fontSize = [
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
    '28px',
    '32px',
    '48px',
  ];
  const Size = ReactQuill.Quill.import('attributors/style/size'); // <<<< ReactQuill exports it

  useEffect(() => {
    if (props.data) {
      setIntroList(props.data);
      Size.whitelist = fontSize;
      ReactQuill.Quill.register(Size, true);
    }
  }, [props.data]);

  const EdittingData = (newData, status) => {
    getEditData(
      newData,
      status,
      IntroList,
      setIntroList,
      props.setData,
      props.article_id,
      from,
      context.getArticleData,
      context.articleKey
    );
    setEditData(null);
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      MoveRow(dragIndex, hoverIndex, IntroList, context, from);
    },
    [IntroList]
  );
  const CardList = List => {
    return (
      <Row style={{ marginTop: 10 }} gutter={[16, 16]}>
        {List.map((data, index) => {
          return (
            <Col span={8} key={index}>
              <ReactDndDragSort
                index={index}
                moveRow={moveRow}
                accept='ArticleDetail'
              >
                <Card
                  className={style.htmlContent}
                  title={data.headline}
                  extra={`No.${index + 1}`}
                  bodyStyle={{ height: 300, overflow: 'hidden' }}
                  hoverable={true}
                  actions={CardButton(
                    data,
                    from,
                    key,
                    setIntroList,
                    IntroList,
                    setEditData,
                    setIsModalOpen,
                    props.readOnly
                  )}
                >
                  {data.description ? (
                    <ReactQuill
                      value={data.description}
                      theme='bubble'
                      readOnly={true}
                    />
                  ) : (
                    ''
                  )}
                </Card>
              </ReactDndDragSort>
            </Col>
          );
        })}
      </Row>
    );
  };
  return (
    <>
      <Space align='baseline'>
        <Title level={5}>{props.title}</Title>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            setIsModalOpen(true);
            setEditData(null);
          }}
          disabled={props.readOnly}
        >
          新增
        </Button>
      </Space>
      {IntroList?.length > 0 ? CardList([...IntroList]) : <Empty />}
      <ModalContext.Provider
        value={{ isModalOpen, setIsModalOpen, EdittingData, EditData, from }}
      >
        <IntroModal readOnly={props.readOnly} />
      </ModalContext.Provider>
    </>
  );
};
export default IntroCard;
