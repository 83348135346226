import { message } from 'antd';
import axios from 'axios';

const msgKey = 'ndewKey';

export const postHeaders = {
  baseURL: `${process.env.REACT_APP_API_URL}/admin-api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const apiRequest = () => {
  const token = localStorage.getItem('token');
  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/admin-api`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const loginApi = body =>
  apiRequest().post(`/account/login`, {
    ...body,
  });

export const changeCategory = (path, body) =>
  apiRequest().patch(path, {
    ...body,
  });

export const updataSort = (path, body) =>
  apiRequest().put(`/${path}/order?${body}`);

const tokenExpired = () => {
  window.location.href = `/login?message=${403}`;
};

export const fetchApi = async (requestIns, params, id) => {
  const res = await requestIns(params, id).catch(error => {
    if (
      error.response.data.status === 401 ||
      error.response.data.status === 403
    ) {
      tokenExpired();
    } else if (error.response) {
      // eslint-disable-next-line
      console.error('Error', error.response.data);
      message.error({ content: error.response.data.message, key: msgKey });
    } else if (error.request) {
      // eslint-disable-next-line
      console.error('Error', error.request);
    } else {
      // eslint-disable-next-line
      console.error('Error', error.message);
      message.error({ content: error.message, key: msgKey });
    }
  });
  if (res) {
    if (res?.data.RF !== '') {
      localStorage.setItem('token', res.data.RF);
    }
  }
  return res && res.data ? res.data : undefined;
};
export const fetchWithPage = async (requestIns, paramsData, pathVars) => {
  const res = await requestIns(
    { size: paramsData.pageSize, page: paramsData.current - 1 },
    pathVars
  ).catch(error => {
    if (
      error.response.data.status === 401 ||
      error.response.data.status === 403
    ) {
      tokenExpired();
    } else if (error.response) {
      message.error({ content: error.response.data.message, key: msgKey });
    } else if (error.request) {
      // eslint-disable-next-line
      console.error('Error', error.request);
    } else {
      message.error({ content: error.message, key: msgKey });
    }
  });
  if (res.data.RF !== '') {
    localStorage.setItem('token', res.data.RF);
  }
  return res && res.data ? res.data : undefined;
};
