import { Helmet, HelmetProvider } from 'react-helmet-async';

const HelmetData = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta
          httpEquiv='Content-Security-Policy'
          content={`
                    default-src 'self' https://*.artifactdev.tw https://www.youtube.com https://*.nttu.edu.tw ;
                    base-uri 'self';
                    block-all-mixed-content;
                    font-src 'self' https: data:;
                    form-action 'self' https://*.artifactdev.tw https://*.nttu.edu.tw;
                    img-src 'self' data: blob: https://*.artifactdev.tw https://*.nttu.edu.tw http://localhost:3001;
                    object-src 'self' https://*.artifactdev.tw https://*.nttu.edu.tw;
                    script-src 'self' 'unsafe-eval';
                    script-src-attr 'none';
                    style-src 'self' 'unsafe-inline' https://*.artifactdev.tw https://*.nttu.edu.tw;
                    upgrade-insecure-requests
                `}
        />
        <meta name='poweredby' content='false' />
        <meta name='Cross-Origin-Embedder-Policy' content='require-corp' />
        <meta name='Cross-Origin-Resource-Policy' content='same-origin' />
        <meta name='Expect-CT' content='max-age=0' />
        <meta name='Origin-Agent-Cluster' content='?1' />
        <meta name='Referrer-Policy' content='no-referrer' />
        <meta
          name='Strict-Transport-Security'
          content='max-age=15552000; includeSubDomains'
        />
        <meta name='X-Content-Type-Options' content='nosniff' />
        <meta name='X-DNS-Prefetch-Control' content='off' />
        <meta name='X-Download-Options' content='noopen' />
        <meta name='X-Frame-Options' content='SAMEORIGIN' />
        <meta name='X-Permitted-Cross-Domain-Policies' content='none' />
        <meta name='X-XSS-Protection' content='0' />
      </Helmet>
    </HelmetProvider>
  );
};
export default HelmetData;
