import { useContext } from 'react';
import { TermListContext } from '.';
import { RemoveTerm } from './TermHook/RemoveTerm';
import { Button, Popconfirm, Space } from 'antd';
import * as icons from 'react-bootstrap-icons';

export const ReadMode = props => {
  const context = useContext(TermListContext);
  const IconBotton = () => {
    const BootstrapIcon = icons[props.tab.icon];
    return (
      <Button
        onClick={() => context.setIsModalOpen(true)}
        shape='circle'
        type='text'
      >
        {BootstrapIcon ? <BootstrapIcon style={{ fontSize: 20 }} /> : '-'}
      </Button>
    );
  };
  return (
    <Space size='small'>
      <IconBotton />
      <div
        onDoubleClick={() => {
          context.setEditingIndex(props.index);
        }}
      >
        {props.tab.label}
      </div>
      <Popconfirm
        title='確定刪除？'
        okText='確定'
        cancelText='取消'
        onConfirm={() => {
          RemoveTerm(props.tab, context.category_list).then(res => {
            context.getTermList('del');
            // setActiveKey({})
          });
        }}
      >
        <Button type='text' shape='circle'>
          x
        </Button>
      </Popconfirm>
    </Space>
  );
};
