import { Cascader, Form } from 'antd';
import { FetchCategory } from '../Category/TermList/TermHook/FetchCategory';
import { FetchItem } from '../Category/ItemList/ItemHook/FetchItem';
import { useContext, useEffect, useState } from 'react';
import { FetchArticle } from '../Category/ArticleList/ArticleHook/FetchArticle';
import { ArticleContext } from '../ArticleEdit';
const TagList = (formName, TermName, itemID, form, readOnly) => {
  const [options, setOptions] = useState([]);
  const [articleChangeValue, setArticleChangeValue] = useState([]);
  const context = useContext(ArticleContext);
  useEffect(() => {
    FetchCategory().then(res => {
      let TERM = res.map(term => ({
        value: term.term_id,
        label: term.term_name,
        isLeaf: false,
        level: 0,
        disableCheckbox: true,
      }));
      if (TermName) {
        let termID = res.find(term => term.term_name === TermName);
        form.setFieldsValue({ item: [termID.value, itemID] });
      }
      setOptions(TERM);
      DefaultOption(TERM);
    });
  }, []);

  const handleLoadData = selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.level === 0
      ? FetchItemOption(targetOption)
      : FetchArticleOption(targetOption);
  };
  const FetchItemOption = async (targetOption, originalArray) => {
    if (!targetOption.children) {
      const res = await FetchItem(targetOption.value);
      targetOption.children = res.map(item => {
        let result = {
          value: item.item_id,
          label: item.item_name,
        };
        formName === 'tag_list' &&
          (result = {
            ...result,
            isLeaf: false,
            level: 1,
            disableCheckbox: true,
          });
        return result;
      });
    }
    originalArray ? setOptions([...originalArray]) : setOptions([...options]);
    return originalArray ? [...originalArray] : [...options];
  };
  const FetchArticleOption = async (targetOption, originalArray) => {
    const res = await FetchArticle(targetOption.value);
    targetOption.children = res.map(article => ({
      value: article.article_id,
      label: article.title,
    }));
    originalArray ? setOptions([...originalArray]) : setOptions([...options]);
    return originalArray ? [...originalArray] : [...options];
  };

  const DefaultOption = originalArray => {
    if (formName === 'tag_list') {
      let tag_list = context.ArticleForm.getFieldsValue().tag_list;
      tag_list.map(async tag => {
        let term_obj = originalArray.find(term => term.value === tag[0]);
        let newOption = await FetchItemOption(term_obj, originalArray);
        let term_obj_forItem = newOption.find(term => term.value === tag[0]);
        let item_obj = term_obj_forItem.children.find(
          item => item.value === tag[1]
        );
        FetchArticleOption(item_obj, originalArray);
      });
    }
    if (formName === 'item_id') {
      let item_id = context.ArticleForm.getFieldsValue().item_id;
      let term_obj = originalArray.find(term => term.value === item_id[0]);
      FetchItemOption(term_obj, originalArray);
    }
    if (formName === 'item') {
      let term_obj = originalArray.find(term => term.label === TermName);
      FetchItemOption(term_obj, originalArray);
    }
  };

  // return formName === 'item' ? (
  //   <Cascader
  //     style={{ width: '100%' }}
  //     options={[...options]}
  //     loadData={handleLoadData}
  //     value={articleChangeValue}
  //     onChange={value =>{ setArticleChangeValue(value)}}
  //   />
  // ) : (
  return (
    <Form.Item name={formName} noStyle>
      <Cascader
        style={{ width: '100%' }}
        options={[...options]}
        loadData={handleLoadData}
        changeOnSelect
        multiple={formName === 'tag_list' && true}
        disabled={readOnly}
      ></Cascader>
    </Form.Item>
  );
  // );
};
export default TagList;
