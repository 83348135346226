import { Navigate, useRoutes } from 'react-router';
import AccList from '../Account/AccList';
import ArticleEdit from '../ArticleEdit';
import Loading from '../Loading';
import NotFound from '../NotFound';
import Category from '../Category';
import PermissionDenied from '../PermissionDenied';
import LoginPage from '../Login';
import PasswordResetPage from '../PasswordReset';
import PublicLayout from '../layout';

const routes = [
  {
    path: '/',
    element: <Navigate to='/loading' />,
  },
  {
    path: '/loading',
    element: <Loading />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/password/reset',
    element: <PasswordResetPage />,
  },
  {
    element: <PublicLayout />,
    children: [
      {
        path: '/Category',
        element: <Category />,
      },
      {
        path: '/Account',
        element: <AccList />,
      },
      {
        path: '/Article',
        element: <ArticleEdit />,
      },
      {
        path: '/PermissionDenied',
        element: <PermissionDenied />,
      },
    ],
  },
];
const Router = () => {
  const router = useRoutes(routes);
  return router;
};

export default Router;
