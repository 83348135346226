import { Col, Input } from 'antd';

export const ItemTabContext = props =>
  props.index === props.editingIndex ? (
    <Col>
      <Input
        key={props.tab.key}
        ref={props.inputRef}
        value={props.value}
        autoFocus={true}
        onChange={value => props.setInputValue(value.target.value)}
        placeholder={props.tab.label}
        onBlur={() => {
          props.setInputValue();
          props.setEditingIndex(-1);
        }}
        onPressEnter={() => {
          props.onEdit(props.tab, props.index);
        }}
      />
    </Col>
  ) : (
    <Col
      span={13}
      style={{ textAlign: 'left' }}
      onDoubleClick={() => {
        props.setEditingIndex(props.index);
        props.setInputValue(props.tab.label);
      }}
    >
      {props.tab.label}
    </Col>
  );
