import axios from 'axios';
import './Loading.css';
import { useEffect } from 'react';
const Loading = () => {
  const token = localStorage.getItem('token');
  useEffect(() => {
    CheakAPI();
  }, []);
  const CheakAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin-api/account`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (res.status === 200) {
        setTimeout("location.href='/Category'", 1500);
        // navigate('/Home');
      } else {
        setTimeout("location.href='/login'", 1500);
      }
    } catch (error) {
      setTimeout("location.href='/login'", 1500);
    }
  };
  return (
    <div class='wrapper'>
      <div class='box-wrap'>
        <div class='box one'></div>
        <div class='box two'></div>
        <div class='box three'></div>
        <div class='box four'></div>
        <div class='box five'></div>
        <div class='box six'></div>
      </div>
    </div>
  );
};
export default Loading;
