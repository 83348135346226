import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Result
      style={{ marginTop: 80 }}
      status='404'
      title='404'
      subTitle='網頁不存在'
      extra={
        <Button type='primary' onClick={() => navigate('/loading')}>
          回到首頁
        </Button>
      }
    />
  );
};
export default NotFound;
