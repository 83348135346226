import { message } from 'antd';
import { fetchApi } from '../../../services/ApiService';
import { editItemInfo, postItemInfo } from '../../../services/ItemApi';

export const EditItemInfo = async (tab, inputValue, activeKey) => {
  const value = inputValue;
  let key = 'EditItemInfo';
  const data = {
    item_info: {
      term_id: activeKey,
      item_name: value,
    },
  };

  const res = await fetchApi(
    !tab ? postItemInfo : editItemInfo,
    data,
    tab?.item_id
  );
  if (res.status === 200) {
    message.success({ content: !tab ? '新增成功' : '修改成功', key });
    let newObj = {
      label: value,
      item_id: !tab ? res.data.item_id : tab.item_id,
    };
    return { newObj, status: true };
  } else {
    message.error({ content: res.data.message, key });
    return { status: false };
  }
};
