export const BasiceFormat = [
  {
    label: '標題（排灣語）',
    value: 'paiwan_title',
    check: true,
  },
  {
    label: '學名',
    value: 'scientific_name',
    check: true,
  },
  {
    label: '分類',
    value: 'item_id',
    enterWay: 'Cascader',
    check: true,
  },
  {
    label: '',
    enterWay: 'Blank'
  },
  {
    label: '經度',
    value: 'longitude',
  },
  {
    label: '緯度',
    value: 'latitude',
  },
  {
    label: '地點 (村)',
    value: 'location',
  },
  {
    label: '流域',
    value: 'watershed',
    check: true,
  },
];
export const BasiceFormat_DataResource = [
  {
    label: '資料來源',
    value: 'data_resource',
    dataIndex: 'data_resource_name',
    check: true,
  },
];
export const PersonnellFormat = [
  {
    label: '報導人',
    value: 'reporter',
    dataIndex: 'reporter_name',
  },
  {
    label: '資料撰寫人',
    value: 'writer',
    dataIndex: 'writer_name',
    check: true,
  },
  {
    label: '族語確認者',
    value: 'affirmant',
    dataIndex: 'affirmant_name',
  },
  {
    label: '文字編輯者',
    value: 'editor',
    dataIndex: 'editor_name',
  },
];
export const OtherDataFormat = [
  {
    label: 'IG 連結',
    value: 'ig_link',
  },
  {
    label: 'facebook 連結',
    value: 'fb_link',
  },
  {
    label: '里山韌性指標連結',
    value: 'sepl_link',
  },
  {
    label: '循環經濟資料庫連結',
    value: 'cedb_link',
  },
  {
    label: '標籤',
    value: 'keyword_name',
  },
  {
    label: '網站內關聯項目',
    value: 'tag',
    enterWay: 'Cascader',
  },
];
export const OtherListFormat = [
  {
    label: '相關網站連結',
    value: 'other_link',
    dataIndex: 'url',
    dataIndex2: 'link_name',
    note: 'link_name',
  },
  {
    label: '其他參考資料',
    value: 'other_resource',
    dataIndex: 'url',
    dataIndex2: 'other_resource_name',
    note: 'link_name',
  },
];
