import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
// dnd拖拽排序
export const ReactDndDragSort = props => {
  const { moveRow, index, children } = props;
  const ref = useRef(null);
  const accept = props.accept;
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type: accept,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const changeRef = drag(drop(ref));
  return (
    <div
      ref={changeRef}
      key={index + 1}
      className={`${isOver ? dropClassName : ''}`}
    >
      <span key={index}>{children}</span>
    </div>
  );
};
