import { message } from 'antd';
import { fetchApi } from '../../../services/ApiService';
import { editTermInfo, postTermInfo } from '../../../services/TermApi';
export const EditTermInfo = async (tab, adding, value) => {
  let key = 'EditTermInfo';
  const data = {
    term_name: value,
    icon: !tab.icon ? '' : tab.icon,
  };

  try {
    const res = await fetchApi(
      adding ? postTermInfo : editTermInfo,
      data,
      tab.term_id
    );

    if (res.status === 200) {
      message.success({ content: adding ? '新增成功' : '修改成功', key });
      return true;
    } else {
      message.error({ content: adding ? '新增失敗' : '修改失敗', key });
    }
  } catch (err) {
    message.error({ content: err.response.data.message, key });
  }
};
