import axios from 'axios';

export const FetchArticle = async activeKey => {
  const token = localStorage.getItem('token');
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin-api/article/list/${activeKey}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
  let result = [];
  result = res.data.data.itemList.map(item => ({
    key: item.article_id,
    ...item,
  }));
  return result;
};
