import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Menu, Layout, Spin } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { FetchInfro } from '../../Hook/FetchInfro';
import { SiderMenuFormat } from './SiderMenuFormat';
import { PublicContext } from '../../App';
const { Sider } = Layout;

const SiderMenu = () => {
  const context = useContext(PublicContext);
  const Location = useLocation();
  const navigate = useNavigate();
  const [selectedKeys, setselectedKeys] = useState([Location.pathname]);
  const [menuList, setMenuList] = useState([]);
  const { setPublicLoading, publicLoading } = context;

  const whd = e => {
    if (e.key !== Location.pathname) {
      setPublicLoading(true);
      navigate(e.key);
    }
  };
  useMemo(() => {
    setselectedKeys(Location.pathname);
  }, [Location.pathname]);

  useEffect(() => {
    FetchInfro().then(res => {
      let newMenu = [...SiderMenuFormat];
      if (res.permission !== 'administrator') {
        newMenu.shift();
      }
      if(res.permission === 'collector') {
        newMenu.shift();
      }
      setMenuList([...newMenu]);
    });
  }, []);
  return (
    <Sider width={250} style={{ backgroundColor: '#fff' }}>
      <Spin tip='Loading...' spinning={publicLoading}>
        <Menu
          theme='light'
          mode='inline'
          defaultselectedkeys={[Location.pathname]}
          items={[...menuList]}
          onClick={whd}
          selectedKeys={selectedKeys}
        />
      </Spin>
    </Sider>
  );
};

export default SiderMenu;
