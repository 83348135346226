import { changeCategory, fetchApi } from '../services/ApiService';
export const ChangeCategory = async (OriginalId, ChangeId, form) => {
  let ChangeCategory = form === 'article' ? 'item' : 'term';
  const res = await fetchApi(changeCategory, `/${form}/${OriginalId}`, {
    [ChangeCategory + '_id']: ChangeId,
  });
  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
};
