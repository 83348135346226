import { message } from 'antd';
import { fetchApi } from '../../../services/ApiService';
import { putTermSort } from '../../../services/TermApi';
export const TermSort = async termList => {
  const sortData = termList.map(pane => pane.term_id);
  let key = 'TermSort';
  const res = await fetchApi(putTermSort, { term_order_list: sortData });
  if (res.status === 200) {
    message.success({ content: '已更新排序', key });
  }
};
