import { useContext, useEffect, useState } from 'react';
import IntroCard from './IntroCard';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ArticleContext } from '..';
import { Space, Typography } from 'antd';
const { Text } = Typography;
const Summary = ({ readOnly }) => {
  const [SummaryList, setSummaryList] = useState([]);
  const context = useContext(ArticleContext);
  useEffect(() => {
    if (context.articleContent.introduction_list) {
      setSummaryList(context.articleContent.introduction_list);
    }
  }, [context.articleContent]);
  return (
    <DndProvider backend={HTML5Backend}>
      <IntroCard
        from='introduction'
        data={SummaryList}
        setData={setSummaryList}
        title={
          <Space size={3}>
            <div style={{ color: 'red' }}>*</div>
            簡介
            <Text style={{ fontWeight: 400 }}>( 必須擁有一個簡介 )</Text>
          </Space>
        }
        article_id={context.articleKey}
        readOnly={readOnly}
      />
    </DndProvider>
  );
};
export default Summary;
