import { message } from 'antd';
import { UpdataArticleSort } from '../../ArticleEditHook/UpdataArticleSort';
export default function MoveRow(
  dragIndex,
  hoverIndex,
  IntroList,
  context,
  from
) {
  let key = 'MoveRow';
  message.loading({ content: '處理中', key });
  UpdataArticleSort(
    IntroList[dragIndex][from + '_id'],
    hoverIndex - dragIndex,
    from
  ).then(res => {
    if (res) {
      message.success({ content: '已更新', key });
      context.getArticleData(context.articleKey, 0, [], from);
    } else {
      message.error({ content: '更新失敗', key });
    }
  });
}
