import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const breadcrumbNameMap = {
  '/Account': '帳號管理',
  '/Category': '類別管理',
  '/Article': '文化及在地產物內容編輯',
};
const IBreadcrumb = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter(i => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return {
      key: url,
      title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
    };
  });
  return <Breadcrumb items={extraBreadcrumbItems}></Breadcrumb>;
};
export default IBreadcrumb;
